import queryString from 'query-string';
import uploadRequest from '../helpers/upload-request';
import makeRequest from '../helpers/make-request';
import httpClient from '../helpers/http-client';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getProductLookUp = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetLookupData`;

  return makeRequest<DTO.GetLookupDataResponse>('GET', url);
};

const getProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
    filterFavorite = false,
  } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/list?`;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({ field: 'name', value: searchText });
  }

  if (filterStatus) {
    search.push({ field: 'status', value: filterStatus });
  }

  if (filterCategory) {
    search.push({ field: 'category', value: filterCategory });
  }

  if (filterFavorite) {
    search.push({ field: 'isstarred', value: filterFavorite.toString() });
  }

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const getSharedProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
    filterFavorite = false,
  } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/share/list?`;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({ field: 'name', value: searchText });
  }

  if (filterStatus) {
    search.push({ field: 'status', value: filterStatus });
  }

  if (filterCategory) {
    search.push({ field: 'category', value: filterCategory });
  }

  if (filterFavorite) {
    search.push({ field: 'isstarred', value: filterFavorite.toString() });
  }

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const createProducts = (product: DTO.CreateProductRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/create`;

  const formData = new FormData();

  formData.append('Name', product.name);
  formData.append('Category', product.category);
  formData.append('Description', product.description);
  formData.append('LaunchDate', product.launchDate);
  formData.append('StartDate', product.startDate);
  formData.append('IsStarred', 'false');
  formData.append('Status', 'Design');

  if (product.sharedWith) {
    formData.append('SharedWith', product.sharedWith);
  }

  if (product.coverImage) {
    formData.append('CoverImage', product.coverImage);
  }

  return makeRequest('POST', url, formData);
};

const cloneProduct = (product: DTO.CloneProductRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${product.originalName}/clone`;
  const formData = new FormData();

  formData.append('name', product.name);
  formData.append('category', product.category);
  formData.append('description', product.description);
  formData.append('launchDate', product.launchDate);
  formData.append('startDate', product.startDate);

  if (product.sharedWith) {
    formData.append('sharedWithUserGroupss', product.sharedWith);
  }

  if (product.coverImage) {
    formData.append('coverImage', product.coverImage);
  }

  return makeRequest<DTO.CloneProductResponse>('POST', url, formData);
};

const updateProduct = (
  productId: string,
  product: DTO.UpdateProductRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/update/${productId}`;

  return makeRequest('POST', url, product);
};

const uploadCoverImage = (
  productId: string,
  file: File | Blob,
  onUploadProgress: (percent: number) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/UploadCoverImage`;
  const formData = new FormData();

  formData.append('id', productId);
  formData.append('coverImage', file);

  return uploadRequest('POST', url, formData, onUploadProgress);
};

const getCoverImage = (filePath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetImage/${filePath}`;

  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getProductDetails = (
  productName: string,
  data: DTO.GetProductDetailsRequest = {}
) => {
  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/GetProduct/${productName}?`;

  const {
    noOfCalculationEngines = 5,
    noOfProductDetails = 5,
    noOfProductDocuments = 5,
    noOfAnalysisReports = 5,
  } = data;

  url += queryString.stringify({
    noOfCalculationEngines,
    noOfProductDetails,
    noOfProductDocuments,
    noOfAnalysisReports,
  });

  return makeRequest<DTO.GetProductDetailsResponse>('GET', url);
};

const setFavoriteProduct = (productId: string, isFavorite: boolean) =>
  updateProduct(productId, { isStarred: isFavorite });

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: DTO.ProductDocType,
  searchTerm: string
) => {
  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/List/${documentType}?`;

  url += queryString.stringify({
    page,
    pageSize,
    sort: '-created',
    pathQuery: searchTerm,
  });

  return makeRequest<DTO.GetProductDocsResponse>('GET', url);
};

const deleteDocs = (
  productName: string,
  fileName: string,
  documentType: DTO.ProductDocType
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/Delete/${fileName}/${documentType}`;

  return makeRequest('DELETE', url);
};

const uploadProductDoc = (
  documentType: DTO.ProductDocType,
  productName: string,
  productDocument: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/create`;
  const formData = new FormData();

  formData.append('ProductDocument', productDocument);
  formData.append('DocumentType', documentType);

  return uploadRequest<DTO.UploadProductDocResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getDocumentDownloadUrl = (
  request: DTO.DownloadDocumentRequest,
  documentType: DTO.ProductDocType,
  token: string
) => {
  const { productName, fileName } = request;

  return `${EXCELENGINE_DOMAIN}/api/v1/Product/${productName}/documents/download/${fileName}/${documentType}?token=${encodeURIComponent(
    token
  )}`;
};

const getRecentChanges = (
  productName: string,
  data: DTO.GetRecentChangeRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/useraction/list/${productName}`;

  if (!data.NextPageToken) {
    delete data.NextPageToken;
  }

  return makeRequest<DTO.GetRecentChangeResponse>('POST', url, data);
};

const getSharedProductDetails = (
  productName: string,
  data: DTO.GetProductDetailsRequest = {}
) => {
  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/share/${productName}?`;

  const {
    noOfCalculationEngines = 5,
    noOfProductDetails = 5,
    noOfProductDocuments = 5,
  } = data;

  url += queryString.stringify({
    productName,
    noOfCalculationEngines,
    noOfProductDetails,
    noOfProductDocuments,
  });

  return makeRequest<DTO.GetProductDetailsResponse>('GET', url);
};

const getAnalysisReportUrl = (
  productName: string,
  serviceName: string,
  token: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/documents/GetContent/${serviceName}/ANALYSISREPORT?token=${token}`;
};

const uploadProductZip = (
  file: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/ValidateProductZip`;

  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<{ data: DTO.ValidateProductZipResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const createProductByZipFile = (data: DTO.CreateProductByZipRequest) =>
  httpClient({
    method: 'POST',
    path: '/api/v1/product/UploadProduct',
    body: data,
  });

export const ProductService = {
  getProducts,
  getSharedProducts,
  createProducts,
  updateProduct,
  uploadCoverImage,
  getCoverImage,
  getProductLookUp,
  setFavoriteProduct,
  getProductDetails,
  getProductDocs,
  uploadProductDoc,
  cloneProduct,
  getDocumentDownloadUrl,
  deleteDocs,
  getRecentChanges,
  getSharedProductDetails,
  getAnalysisReportUrl,
  uploadProductZip,
  createProductByZipFile,
};
