import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getProducts = (data: DTO.GetKanbanRoadMapProductsRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/productkanban/GetProducts`;

  const { filterCategory, searchText, sort, sortBy } = data;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({ field: 'name', value: searchText });
  }

  if (filterCategory) {
    search.push({ field: 'category', value: filterCategory });
  }

  return makeRequest<{ data: DTO.Product[] }>('POST', url, {
    search,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
  });
};

const getStatuses = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/productkanban/GetStatuses`;

  return makeRequest<{ data: string[] }>('GET', url);
};

const updateColumn = (
  columnName: string,
  fromColumnName: string,
  columnIndex: number
) => {
  const columnIndexPath = columnIndex + 1 ? `/${columnIndex + 1}` : '';
  const url =
    `${EXCELENGINE_DOMAIN}/api/v1/productkanban/updatestatus` +
    `/${fromColumnName}/${columnName}${columnIndexPath}`;

  return makeRequest('PUT', url);
};

const addColumn = (columnName: string, columnIndex: number) => {
  const url =
    `${EXCELENGINE_DOMAIN}/api/v1/productkanban/createstatus` +
    `/${columnName}/${columnIndex + 1}`;

  return makeRequest('POST', url);
};

const deleteColumn = (status: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/productkanban/deletestatus/${status}`;

  return makeRequest('DELETE', url);
};

const updateKanbanStatus = (productId: string, status: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/productkanban/updateproductstatus/${status}/${productId}`;

  return makeRequest('PUT', url);
};

export const ProductsBoardServices = {
  getProducts,
  getStatuses,
  updateColumn,
  addColumn,
  deleteColumn,
  updateKanbanStatus,
};
