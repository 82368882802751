import jwt from 'jsonwebtoken';
import { UserConstants } from '../constants';
import makeRequest from './make-request';
import { getValidRoleAndGroup } from './get-valid-role-group';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;

export const verifyToken = async (userAuth: { id_token: string }) => {
  const publicKey = await makeRequest<{ key: string }>(
    'GET',
    `${USERSTORE_DOMAIN}/users/api/key`
  ).then(({ payload }) => payload.key);

  try {
    const decodedJwt = jwt.verify(userAuth.id_token || '', publicKey, {
      algorithms: ['RS512'],
      ignoreExpiration: false,
    }) as DTO.UserJWTData;

    return decodedJwt;
  } catch (err) {
    return null;
  }
};

export const isAdmin = (decodedJwt: DTO.UserJWTData) => {
  const role =
    decodedJwt &&
    decodedJwt.groups &&
    getValidRoleAndGroup(decodedJwt.groups).role;

  return !!role && role === UserConstants.ROLE_SUPERVISOR;
};

export const isSuperuser = (decodedJwt: DTO.UserJWTData) => {
  return !!decodedJwt && decodedJwt.is_superuser;
};
