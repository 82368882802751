import React, { useRef } from 'react';
import { Menu, Layout } from 'antd';
import { useLocation, Link } from 'react-router-dom';

import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Logo } from '../Common';
import { colorHexToRgba } from '../../helpers';

const { Item, ItemGroup } = Menu;
const { Sider } = Layout;

const SideBarMenuWrapper = styled(Sider)`
  && {
    z-index: 6;
    width: 208px !important;
    max-width: 208px !important;
    min-width: 208px !important;
    overflow: auto;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.gray6};
    .ant-menu {
      background-color: ${({ theme }) => theme.color.gray6};
      border-top-width: 0;
      margin-top: 50px;

      .ant-menu-vertical .ant-menu-item {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }

    .ant-menu-item-group-title {
      padding: 0 28px !important;
      color: ${({ theme }) => theme.color.dark};
      font-size: 16px;
      font-weight: bold;
    }

    .ant-menu-item > a {
      color: ${({ theme }) => theme.color.dark};
      font-size: 16px;
    }

    .ant-menu-item {
      margin: 0;
    }

    .ant-menu-item:hover {
      background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.5)};
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.5)};
    }

    .ant-menu-item-group {
      margin-bottom: 50px;
    }

    .ant-menu-vertical .ant-menu-item:not(:last-child) {
      margin-bottom: 0px !important;
    }
  }
`;

const LogoContainer = styled.div`
  background-color: ${({ theme }) => theme.vars.gray6};
  text-align: center;
  padding: 18px;
  height: 100px;
  line-height: 3.6;
`;

export interface SideMenuItem {
  to: string;
  title: string;
  permission?: boolean;
  disabled?: boolean;
}

export interface SideMenuGroup {
  key: string;
  groupName: string;
  isNormalUser?: boolean;
  groups: SideMenuItem[];
}

interface OptionsLayoutSideMenuProps {
  menuItems: SideMenuGroup[];
}

const AdminLayoutSideMenu: React.FC<OptionsLayoutSideMenuProps> = ({
  menuItems,
}) => {
  const location = useLocation();
  const intl = useIntl();
  const sidebarMenuWrapperRef = useRef<HTMLDivElement>(null);

  const renderItems = (menuGroup: SideMenuGroup[]) => {
    return menuGroup.map(
      ({ groups, key, groupName, isNormalUser }) =>
        !isNormalUser && (
          <ItemGroup key={key} title={intl.formatMessage({ id: groupName })}>
            {groups.map(
              ({ title, to, permission, disabled }) =>
                permission && (
                  <Item disabled={disabled} key={title}>
                    <Link to={to}>
                      <span>
                        <span>
                          <FormattedMessage id={title} />
                        </span>
                      </span>
                    </Link>
                  </Item>
                )
            )}
          </ItemGroup>
        )
    );
  };

  return (
    <>
      <SideBarMenuWrapper>
        <LogoContainer>
          <Link to="/">
            <Logo type="OnWhite" height="40px" />
          </Link>
        </LogoContainer>
        <Menu
          mode="vertical"
          selectable
          selectedKeys={[location.pathname]}
          getPopupContainer={() =>
            sidebarMenuWrapperRef.current || document.body
          }
        >
          {renderItems(menuItems)}
        </Menu>
      </SideBarMenuWrapper>
    </>
  );
};

export default AdminLayoutSideMenu;
