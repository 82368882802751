const defaultRevision = '0.1.0';

const updateRevision = (value: number, revision?: string) => {
  if (revision && revision.split('.').length === 3) {
    const revisionComponents = revision.split('.');
    const major = Number(revisionComponents[1]) + value;
    if (major !== 0) {
      return `${revisionComponents[0]}.${major}.${revisionComponents[2]}`;
    }
    return defaultRevision;
  }
  return defaultRevision;
};

const decreaseRevision = (revision?: string) => {
  return updateRevision(-1, revision);
};

const increaseRevision = (revision?: string) => {
  return updateRevision(1, revision);
};

const compareRevision = (revision1: string, revision2: string) => {
  if (
    revision1 &&
    revision1.split('.').length === 3 &&
    revision2 &&
    revision2.split('.').length === 3
  ) {
    const major1 = Number(revision1.split('.')[1]);
    const major2 = Number(revision2.split('.')[1]);
    return major1 > major2;
  }

  return revision1 > revision2;
};

export { decreaseRevision, increaseRevision, compareRevision };
