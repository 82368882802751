import moment from 'moment';
import { EngineConstants } from '../constants';
import { ApiError, arrayRemoveFirst, downloadBlob } from '../helpers';
import { EngineService } from '../services';
import { AlertActions } from './alert.actions';
import {
  EngineAction,
  EngineThunkAction,
  EngineThunkActionSync,
} from './types';

const getProductEngines = (
  productName: string,
  data: DTO.GetProductEnginesRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_REQUEST,
      payload: { productName, ...data },
    });

    const { payload, status } = await EngineService.getProductEngines(
      productName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_SUCCESS,
      payload: {
        productName,
        ...data,
        engines: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_FAILURE,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string
): EngineThunkAction => async (dispatch, getState) => {
  try {
    const {
      productEngines: {
        isLoadingDetails,
        selectedEngineName,
        selectedVersionId,
      },
    } = getState();

    if (
      isLoadingDetails &&
      selectedEngineName === serviceName &&
      selectedVersionId === versionId
    ) {
      return;
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST,
      payload: {
        productName,
        serviceName,
        versionId,
      },
    });

    const { payload, status } = await EngineService.getProductEngineDetails(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS,
      payload: {
        productName,
        serviceName,
        engine: payload.data,
        versionId,
      },
    });

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_SUCCESS,
      payload: {
        serviceName,
        versions: payload.data.versions,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
        versionId,
      },
    });
  }
};

const updateProperties = (
  productName: string,
  serviceName: string,
  data: DTO.UpdateEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_REQUEST,
    });

    const updateData: DTO.UpdateProductEnginePropsRequest = {
      BookProperties: data.properties,
      effectiveEndDate: data.effectiveEndDate,
      effectiveStartDate: data.effectiveStartDate,
      XlInputs: data.inputs.map(({ name, description }) => ({
        originalName: name,
        newName: name,
        description,
      })),
      XlOutputs: data.outputs.map(({ name, description }) => ({
        originalName: name,
        newName: name,
        description,
      })),
      Id: data.id,
      Version: data.version,
      masterReferenceDetail: data.masterReferenceDetail,
    };

    updateData.BookProperties = arrayRemoveFirst(
      updateData.BookProperties,
      prop => prop.name === 'Book-ServiceName'
    );

    const { payload, status } = await EngineService.updateProductEngine(
      productName,
      serviceName,
      updateData
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS,
      payload: {
        serviceName,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_FAILURE,
      payload: {
        serviceName,
        error: msg,
      },
    });
  }
};

const uploadRegressionTestInput = (
  productName: string,
  file: File
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.REGRESSION_TEST_UPLOAD_START });

    const { status, payload } = await EngineService.uploadRegressionTestInput(
      productName,
      file,
      uploadProgress => {
        if (uploadProgress === 100) {
          dispatch({
            type: EngineConstants.REGRESSION_TEST_EXECUTE_START,
          });

          return;
        }

        dispatch({
          type: EngineConstants.REGRESSION_TEST_UPLOAD_PROGRESS,
          payload: {
            uploadProgress,
          },
        });
      },
      xhrRef => {
        dispatch({
          type: EngineConstants.REGRESSION_TEST_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.REGRESSION_TEST_SUCCESS,
      payload: { result: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.REGRESSION_TEST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const increaseRegressionTestExecuteProgress = (executeProgress: number) => {
  if (executeProgress >= 90) return {};

  return {
    type: EngineConstants.REGRESSION_TEST_EXECUTE_PROGRESS,
    payload: {
      executeProgress: executeProgress + 10,
    },
  };
};

const resetUploadRegressionTestInput = () => ({
  type: EngineConstants.REGRESSION_TEST_RESET,
});

const updateEngineFavorite = (
  productName: string,
  engine: DTO.Engine,
  favorite: boolean
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_REQUEST,
      payload: {
        serviceName: engine.originalServiceName,
        favorite,
      },
    });

    const { status, payload } = await EngineService.updateEngineDetail(
      productName,
      engine.originalServiceName,
      {
        Isstarred: favorite,
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_SUCCESS,
      payload: {
        serviceName: engine.originalServiceName,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_FAILURE,
      payload: {
        serviceName: engine.originalServiceName,
        error: msg,
      },
    });
  }
};

const uploadEngine = (
  productName: string,
  file: File,
  noPublish?: boolean
): EngineThunkAction => async (dispatch, getState) => {
  try {
    dispatch({ type: EngineConstants.ADD_ENGINE_UPLOAD_START });

    const {
      status: uploadStatus,
      payload: uploadPayload,
    } = await EngineService.uploadEngine(
      productName,
      file,
      uploadProgress => {
        if (uploadProgress === 100) {
          dispatch({
            type: EngineConstants.ADD_ENGINE_EXECUTE_START,
          });

          return;
        }

        dispatch({
          type: EngineConstants.ADD_ENGINE_UPLOAD_PROGRESS,
          payload: {
            uploadProgress,
          },
        });
      },
      xhrRef => {
        dispatch({
          type: EngineConstants.ADD_ENGINE_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (
      uploadStatus !== 200 ||
      uploadPayload.status === 'Error' ||
      uploadPayload.status == null
    ) {
      if (
        uploadPayload.errorCode &&
        uploadPayload.Data &&
        [
          'INVALID_ENGINE_CONFIGURATION',
          'REFERENCE_RANGE_ERROR',
          'PRODUCT_TUTORIAL_UPLOAD_ERROR',
          'INVALID_SOLVE_NAMERANGES',
        ].includes(uploadPayload.errorCode)
      ) {
        const { Error, CorrelationId } = uploadPayload.Data;
        const {
          language: { intl },
        } = getState();

        dispatch({
          type: EngineConstants.ADD_ENGINE_ERROR,
          payload: {
            error: [
              ...Error.map(({ Key, Value }) =>
                intl.formatMessage({ id: Key }, { values: Value.join(', ') })
              ),
              `(${CorrelationId})`,
            ].join('<br />'),
          },
        });

        return;
      }

      throw new ApiError(uploadPayload);
    }

    const { data } = uploadPayload;

    const engineExists =
      uploadPayload.status === 'Warning' &&
      uploadPayload.errorCode ===
        EngineConstants.WARNING_CODE_ENGINE_EXISTS_ALREADY;

    data.releaseNoteRequired = engineExists;

    dispatch({
      type: EngineConstants.ADD_ENGINE_SUCCESS,
      payload: { result: data, noPublish },
    });

    if (uploadPayload.message && !noPublish) {
      dispatch(AlertActions.success(uploadPayload.message));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.ADD_ENGINE_ERROR,
      payload: {
        error: msg,
      },
    });
  }
};

const updateActivePeriod = (
  effectiveStartDate: Date,
  effectiveEndDate: Date,
  isMasterReferenceApplied: boolean,
  dateTypeOption?: string,
  masterReferenceDetail?: DTO.MasterReferenceEffectiveDetail,
  masterReferenceFile?: string
): EngineAction => ({
  type: EngineConstants.UPDATE_ACTIVE_PERIOD,
  payload: {
    activePeriod: {
      effectiveEndDate,
      effectiveStartDate,
      isMasterReferenceApplied,
      dateTypeOption,
      masterReferenceFile,
      masterReferenceDetail: isMasterReferenceApplied
        ? masterReferenceDetail
        : undefined,
    },
  },
});

const updateReleaseNote = (releaseNote: string): EngineAction => ({
  type: EngineConstants.UPDATE_RELEASE_NOTE,
  payload: {
    releaseNote,
  },
});

const publishNewEngine = (
  productName: string,
  serviceName: string,
  data?: DTO.PublishEngineRequest,
  keepOpenModal?: boolean
): EngineThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE,
    });

    const { status, payload } = await EngineService.publishEngine(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE_SUCCESS,
      payload: {
        keepOpenModal,
        effectiveEndDate: data?.effectiveEndDate,
        effectiveStartDate: data?.effectiveStartDate,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};

const increaseExecuteProgress = (executeProgress: number) => {
  if (executeProgress >= 90) return {};

  return {
    type: EngineConstants.ADD_ENGINE_EXECUTE_PROGRESS,
    payload: {
      executeProgress: executeProgress + 10,
    },
  };
};

const resetAddEngine = (): EngineAction => ({
  type: EngineConstants.ADD_ENGINE_RESET,
});

const showUpdateConfirm = (data: DTO.UpdateEngineRequest): EngineAction => ({
  type: EngineConstants.SHOW_UPDATE_CONFIRM,
  payload: { data },
});

const updateEngineProps = (data: DTO.UpdateEngineRequest): EngineAction => ({
  type: EngineConstants.UPDATE_ENGINE_PROPS,
  payload: { data },
});
const cancelPublishEngine = (): EngineAction => ({
  type: EngineConstants.CANCEL_PUBLISH_ENGINE,
});

const cancelUpdateConfirm = (): EngineAction => ({
  type: EngineConstants.CANCEL_UPDATE_CONFIRM,
});

const resetProductEngines = (): EngineAction => ({
  type: EngineConstants.PRODUCT_ENGINES_RESET,
});

const deleteEngine = (
  productName: string,
  serviceName: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.DELETE_ENGINE_REQUEST,
    payload: { productName, serviceName },
  });

  try {
    const { status, payload } = await EngineService.deleteEngine(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.DELETE_ENGINE_SUCCESS,
      payload: {
        productName,
        serviceName,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.deleteEngine.success', {
        serviceName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DELETE_ENGINE_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
      },
    });
  }
};

const downloadEngine = (
  originalServiceName: string,
  documentVersionId: string
): EngineThunkAction => (_, getState) => {
  const userAuth = getState().auth.userAuth;

  if (!userAuth) return;

  const { id_token } = userAuth;

  const downloadUrl = EngineService.getEngineDownloadUrl(
    originalServiceName,
    documentVersionId,
    id_token
  );

  window.location.href = downloadUrl;
};

const downloadEncryptedEngine = (
  originalServiceName: string,
  documentVersionId: string
): EngineThunkAction => (_, getState) => {
  const userAuth = getState().auth.userAuth;

  if (!userAuth) return;

  const { id_token } = userAuth;

  const downloadUrl = EngineService.getEncryptedEngineDownloadUrl(
    originalServiceName,
    documentVersionId,
    id_token
  );

  window.location.href = downloadUrl;
};

const apiTesterExecuteTest = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.API_TEST_EXECUTE });
    const { status, payload } = await EngineService.executeProductEngine(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === 'NO_ACTIVE_VERSION') {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
      } else {
        throw new ApiError(payload);
      }
    } else {
      if (
        payload.status === 'Warning' &&
        payload.errorCode === 'ENGINE_INACTIVE_WARNING'
      ) {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL,
        });
      } else {
        dispatch({
          type: EngineConstants.API_TEST_HIDE_ED_WARNING_MODAL,
        });
      }
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_SUCCESS,
        payload: {
          result: {
            ...payload.data,
            ServiceCategories:
              payload.data.ServiceCategories ||
              (data.ServiceCategories && data.ServiceCategories.length > 0
                ? data.ServiceCategories
                : undefined),
          },
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterExecuteInputsTest = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.API_TEST_EXECUTE_INPUTS });
    const { status, payload } = await EngineService.executeProductEngineInputs(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === 'NO_ACTIVE_VERSION') {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
      } else {
        throw new ApiError(payload);
      }
    } else {
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_INPUTS_SUCCESS,
        payload: {
          xlInputs: payload.data.xlInputs,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterWarningHide = () => ({
  type: EngineConstants.API_TEST_HIDE_ED_WARNING_MODAL,
});

const apiTesterWarningShow = () => ({
  type: EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL,
});

const apiTesterModalHide = () => ({
  type: EngineConstants.API_TEST_HIDE_ERROR_MODAL,
});

const downloadRegressionTestResult = (
  productName: string,
  resultFile: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD,
    });

    const { status, payload } = await EngineService.getRegressionTestResultFile(
      productName,
      resultFile
    );

    if (status !== 200) {
      throw new ApiError(payload);
    }

    downloadBlob(payload.blob, resultFile);

    dispatch({
      type: EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD_SUCCESS,
      payload: {
        productName,
        resultFile,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterReset = (): EngineAction => ({
  type: EngineConstants.API_TEST_RESET,
});

const generateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GENERATE_TEST_CASES_REQUEST,
      payload: { productName, serviceName },
    });

    const { payload, status } = await EngineService.generateTestCases(
      productName,
      serviceName,
      versionId,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GENERATE_TEST_CASES_SUCCESS,
      payload: {
        generatedTestCasesFile: payload.data.ResultFileName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GENERATE_TEST_CASES_FAILURE,
      payload: { error: msg },
    });
  }
};

const downloadGeneratedTestCasesFile = (
  productName: string,
  serviceName: string,
  generatedTestCasesFile: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GENERATED_TEST_DOWNLOAD,
    });

    const { status, payload } = await EngineService.getGeneratedTestCasesFile(
      productName,
      serviceName,
      generatedTestCasesFile
    );

    if (status !== 200) {
      throw new ApiError(payload);
    }

    downloadBlob(payload.blob, generatedTestCasesFile);

    dispatch({
      type: EngineConstants.GENERATED_TEST_DOWNLOAD_SUCCESS,
      payload: {
        productName,
        serviceName,
        generatedTestCasesFile,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GENERATED_TEST_DOWNLOAD_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getEngineVersions = (
  productName: string,
  serviceName: string
): EngineThunkAction<DTO.EngineVersion[]> => async dispatch => {
  try {
    const { status, payload } = await EngineService.getVersions(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_SUCCESS,
      payload: {
        serviceName,
        versions: payload.data,
      },
    });

    return payload.data;
  } catch (error) {
    dispatch(AlertActions.error(error));

    return [];
  }
};

const downloadEngineFile = (
  productName: string,
  serviceName: string,
  version: string,
  encrypted: boolean,
  fileName: string
): EngineThunkActionSync => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  const downloadUrl = EngineService.getDownloadEngineUrl(
    productName,
    serviceName,
    id_token,
    version,
    fileName,
    encrypted
  );

  window.location.href = downloadUrl;
};

const downloadSwagger = (
  request: DTO.DownloadSwaggerRequest
): EngineThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  window.location.href = EngineService.getSwaggerDownloadUrl(request, id_token);
};

const downloadOutputFile = (
  product: string,
  engine: string,
  url: string
): EngineThunkAction => async dispatch => {
  const { status, payload } = await EngineService.downloadExecuteOutputFile(
    url
  );
  if (status !== 200) {
    throw new ApiError(payload);
  }
  downloadBlob(
    payload.blob,
    `${product}_${engine}_output_${moment()
      .utc()
      .format()}.xlsx`
  );
  dispatch({
    type: EngineConstants.GET_EXECUTE_OUTPUT_SUCCESS,
  });
};

const downloadOutput = (
  product: string,
  engine: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_REQUEST,
    });

    const { status, payload } = await EngineService.getExecuteOutputFile(
      product,
      engine,
      data
    );
    if (status !== 200) {
      throw new ApiError(payload);
    }
    dispatch(downloadOutputFile(product, engine, payload.data));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getProxyUrl = (
  productName: string,
  serviceName: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    const { status, payload } = await EngineService.getSurfixUrl(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode === 'PROXY_URL_NOT_FOUND') {
        dispatch({
          type: EngineConstants.CUSTOM_URL_SUCCESS,
          payload: {
            data: { urlSuffix: '', productName, serviceName },
          },
        });
        return;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.CUSTOM_URL_SUCCESS,
      payload: {
        data: payload.data,
        productName,
        serviceName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createProxyUrl = (
  productName: string,
  serviceName: string,
  urlSurffix: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });
    dispatch({
      type: EngineConstants.CUSTOM_URL_EXIST_HIDE_MODAL,
    });

    const { status, payload } = await EngineService.saveProxyUrl({
      ProductName: productName,
      ServiceName: serviceName,
      UrlSuffix: urlSurffix,
    });

    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode === 'PROXY_URL_ALREADY_EXISTS') {
        dispatch({
          type: EngineConstants.CUSTOM_URL_EXIST,
        });
      }
    }

    dispatch(getProxyUrl(productName, serviceName));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteProxyUrl = (
  proxyUrl: string,
  productName: string,
  serviceName: string,
  urlSurffix: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    await EngineService.deleteProxyUrl(proxyUrl);

    dispatch(createProxyUrl(productName, serviceName, urlSurffix));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadCUrl = (request: DTO.DownloadCUrlRequest): EngineThunkAction => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  window.location.href = EngineService.getCUrlDownloadUrl(request, id_token);
};

const restoreVersion = (
  productName: string,
  serviceName: string,
  versionId: string,
  revision: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.RESTORE_VERSION_REQUEST,
  });

  try {
    const { status, payload } = await EngineService.restoreVersion(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: EngineConstants.RESTORE_VERSION_SUCCESS,
      payload: {
        latestVersionId: data,
        selectedEngineName: serviceName,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.restoreVersion.success', {
        serviceName,
        revision,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.RESTORE_VERSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const EngineActions = {
  getProductEngines,
  getProductEngineDetails,
  showUpdateConfirm,
  cancelUpdateConfirm,
  updateProperties,
  updateEngineFavorite,
  publishNewEngine,
  uploadEngine,
  cancelPublishEngine,
  resetAddEngine,
  increaseExecuteProgress,
  deleteEngine,
  downloadEngine,
  downloadEncryptedEngine,
  resetProductEngines,
  apiTesterExecuteTest,
  apiTesterExecuteInputsTest,
  apiTesterReset,
  apiTesterWarningHide,
  apiTesterWarningShow,
  uploadRegressionTestInput,
  resetUploadRegressionTestInput,
  increaseRegressionTestExecuteProgress,
  downloadRegressionTestResult,
  generateTestCases,
  downloadGeneratedTestCasesFile,
  downloadEngineFile,
  downloadSwagger,
  downloadOutput,
  getEngineVersions,
  updateActivePeriod,
  apiTesterModalHide,
  getProxyUrl,
  createProxyUrl,
  deleteProxyUrl,
  downloadCUrl,
  updateReleaseNote,
  updateEngineProps,
  restoreVersion,
};
