export const GET_PRODUCT_DOCS_REQUEST = 'GET_PRODUCT_DOCS_REQUEST';
export const GET_PRODUCT_DOCS_SUCCESS = 'GET_PRODUCT_DOCS_SUCCESS';
export const GET_PRODUCT_DOCS_FAILURE = 'GET_PRODUCT_DOCS_FAILURE';

export const ADD_DOCUMENT_UPLOAD_START = 'ADD_DOCUMENT_UPLOAD_START';
export const ADD_DOCUMENT_UPLOAD_PROGRESS = 'ADD_DOCUMENT_UPLOAD_PROGRESS';
export const ADD_DOCUMENT_UPLOAD_XHR_REF = 'ADD_DOCUMENT_UPLOAD_XHR_REF';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR';

export const ADD_DOCUMENT_RESET = 'ADD_DOCUMENT_RESET';
export const DOCUMENT_LIST_RESET = 'DOCUMENT_LIST_RESET';

export const DELETE_DOC_REQUEST = 'DELETE_DOC_REQUEST';
export const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS';
export const DELETE_DOC_FAILURE = 'DELETE_DOC_FAILURE';
