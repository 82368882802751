import { Userpilot } from 'userpilot';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

Userpilot.initialize(process.env.REACT_APP_USERPILOT_APP_TOKEN);

const userPilotSelector = createSelector<
  STATES.AppState,
  STATES.AuthState,
  STATES.UserState,
  { userId: string; userInfo: STATES.UserState['userInfo'] }
>(
  ({ auth }) => auth,
  ({ user }) => user,
  ({ userId }, { userInfo }) => ({ userId, userInfo })
);

const useUserpilot = (productName = '') => {
  const { userInfo, userId } = useSelector(userPilotSelector);
  const { pathname, hash, search } = useLocation();

  useEffect(() => {
    if (userInfo) {
      const { displayName } = userInfo;

      Userpilot.identify(userId, {
        name: displayName,
        productName,
      });
    }
  }, [userInfo, userId, productName]);

  useEffect(() => {
    Userpilot.reload();
  }, [hash, search, pathname, userInfo]);
};

export default useUserpilot;
