import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ProductsActions } from '../actions';
import { useAppDispatch } from './useAppDispatch';

const productCoverImgSelector = (coverPath: string | null | undefined) => ({
  products: { cachedFileUrls },
}: STATES.AppState) => {
  return coverPath && cachedFileUrls[coverPath];
};

const downloadingCoverImagePath: { [coverImgPath: string]: boolean } = {};

const useProductCoverImages = (coverPath: string | null | undefined) => {
  const dispatch = useAppDispatch();
  const fileUrl = useSelector(productCoverImgSelector(coverPath));

  useEffect(() => {
    if (!coverPath || (!fileUrl && downloadingCoverImagePath[coverPath])) {
      return;
    }

    const downloadCoverImagePath = async () => {
      await dispatch(ProductsActions.getProductCoverImage(coverPath));
      delete downloadCoverImagePath[coverPath];
    };

    if (!fileUrl) {
      downloadCoverImagePath[coverPath] = true;
      downloadCoverImagePath();
    }
  }, [fileUrl, coverPath, dispatch]);

  return fileUrl;
};

export { useProductCoverImages };
