import React, { FC, useRef, useEffect } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import styled from 'styled-components';
import lottie from 'lottie-web/build/player/lottie_light_html';
import animationData from '../../../assets/logo-animation.json';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const StyledLottieContainer = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: relative;
`;

const StyledSpin = styled(Spin)`
  max-height: none !important;
  min-height: 100px;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px !important;
    margin-top: -50px !important;
  }
`;

interface LoadingGifProps {
  width?: number;
  height?: number;
}

type InlineLoadingGifProps = LoadingGifProps;

const LoadingGif: FC<LoadingGifProps> = ({ width = 100, height = 100 }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation =
      ref.current &&
      lottie.loadAnimation({
        container: ref.current,
        renderer: 'html',
        ...defaultLottieOptions,
      });

    return () => {
      animation && animation.destroy();
    };
  }, []);

  return <StyledLottieContainer ref={ref} width={width} height={height} />;
};

const InlineLoadingGifContainer = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const InlineLoadingGif: FC<InlineLoadingGifProps> = ({
  width = 100,
  height = 100,
}) => (
  <InlineLoadingGifContainer>
    <LoadingGif width={width} height={height} />
  </InlineLoadingGifContainer>
);

interface LoadingIndicatorProps extends SpinProps {
  flexFill?: boolean;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  flexFill,
  wrapperClassName,
  ...spinProps
}) => {
  return (
    <StyledSpin
      {...spinProps}
      indicator={<LoadingGif />}
      wrapperClassName={
        flexFill ? `flex-fill ${wrapperClassName}` : wrapperClassName
      }
    />
  );
};

Spin.setDefaultIndicator(<LoadingIndicator />);

export { LoadingIndicator, InlineLoadingGif };
