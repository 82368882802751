import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { SystemActions } from '../actions';

const lookupDataSelector = ({
  system: { isLoadingLookupData, lookupData },
}: STATES.AppState) => ({
  isLoadingLookupData,
  lookupData,
});

const mapResponseToLookUpDataEntry = (
  data: DTO.LookupData,
  key: keyof DTO.LookupData
): DTO.LookUpDataEntry[] => {
  const rawDataForKey = data[key];

  if (Array.isArray(rawDataForKey)) {
    return rawDataForKey.map(({ Key, Value }) => ({
      key: Key,
      value: Value,
    }));
  }

  return [];
};

const useLookupData = () => {
  const dispatch = useDispatch();
  const { isLoadingLookupData, lookupData } = useSelector(lookupDataSelector);

  useEffect(() => {
    if (!lookupData) {
      dispatch(SystemActions.getLookupData());
    }
    // Only run on didMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const mappedLookupData = useMemo<{
    coverImages: DTO.LookUpDataEntry[];
    productStatuses: DTO.LookUpDataEntry[];
    productCategories: DTO.LookUpDataEntry[];
    executePurposes: DTO.LookUpDataEntry[];
    releaseNote: string;
    releaseVersion: number;
  }>(() => {
    if (!lookupData) {
      return {
        coverImages: [],
        productStatuses: [],
        productCategories: [],
        executePurposes: [],
        releaseNote: '',
        releaseVersion: 0,
      };
    }

    const coverImages = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.CoverImages'
    );

    coverImages.forEach(coverImage => {
      coverImage.value = coverImage.value.substr(1);
    });

    const productCategories = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.ProductCategories'
    );
    const productStatuses = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.ProductStatus'
    );
    const executePurposes = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.ExecutePurpose'
    );
    const releaseNote = lookupData['Metadata.ReleaseNote'];
    const releaseVersion = lookupData['Metadata.ReleaseVersion'];

    return {
      coverImages,
      productCategories,
      productStatuses,
      executePurposes,
      releaseNote,
      releaseVersion,
    };
  }, [lookupData]);

  return {
    ...mappedLookupData,
    lookupData,
    isLoadingLookupData,
  };
};

export { useLookupData };
