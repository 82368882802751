import { ProductDocsAction } from '../actions/types';
import { ProductDocsConstant } from '../constants';
import { arrayRemoveFirst } from '../helpers';

const initialList: STATES.ProductDocList = {
  isLoading: false,
  docs: [],
  total: 0,
  refreshTrigger: 0,
};

const initialState: STATES.ProductDocsState = {
  PRODUCTDETAILS: { ...initialList },
  PRODUCTDOCUMENT: { ...initialList },
  ANALYSISREPORT: { ...initialList },
};

export const productDocs = (
  state = initialState,
  action: ProductDocsAction
): STATES.ProductDocsState => {
  switch (action.type) {
    case ProductDocsConstant.GET_PRODUCT_DOCS_REQUEST: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          isLoading: true,
        },
      };
    }
    case ProductDocsConstant.GET_PRODUCT_DOCS_SUCCESS: {
      const { docs, total, documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          docs,
          total,
          isLoading: false,
        },
      };
    }
    case ProductDocsConstant.GET_PRODUCT_DOCS_FAILURE: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          isLoading: false,
        },
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_SUCCESS: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          refreshTrigger: state[documentType].refreshTrigger + 1,
        },
      };
    }
    case ProductDocsConstant.DELETE_DOC_SUCCESS: {
      const { fileName, documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          docs: arrayRemoveFirst(
            state[documentType].docs,
            doc => doc.fileName === fileName
          ),
          refreshTrigger: state[documentType].refreshTrigger + 1,
        },
      };
    }
    case ProductDocsConstant.DOCUMENT_LIST_RESET: {
      const { type } = action.payload;

      return {
        ...state,
        [type]: {
          ...state[type],
          ...initialList,
        },
      };
    }
    default:
      return state;
  }
};
