import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';
import httpClient from '../helpers/http-client';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const trimSplash = (path: string) => path.replace(/^\/+|\/+$/g, '');

const getFoldersAndFiles = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  request: DTO.GetFoldersAndFilesRequest
) => {
  const {
    page = 1,
    pageSize = 10,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  const rootPath = root === 'home' ? 'getfolders' : 'getsharedfolders';

  const sortOrder = sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '';

  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/${rootPath}` +
    `?path=${trimSplash(folderPath)}` +
    `&page=${page}` +
    `&pageSize=${pageSize}` +
    `&sort=${sortOrder}` +
    `&searchText=${searchText}`;

  return makeRequest<DTO.GetFoldersAndFilesResponse>('GET', url);
};

const getFolders = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  request: DTO.GetFoldersRequest
) => {
  const {
    page = 1,
    pageSize = 100,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  const rootPath = root === 'home' ? 'getfolderlist' : 'getsharedfolderlist';

  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/${rootPath}` +
    `?path=${trimSplash(folderPath)}`;

  const body = {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search: searchText ? [{ field: 'name', value: searchText }] : [],
  };

  return makeRequest<DTO.GetFoldersResponse>('POST', url, body);
};

const getFiles = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  request: DTO.GetFilesRequest
) => {
  const {
    page = 1,
    pageSize = 10,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  const rootPath =
    root === 'home' ? 'getdocumentlist' : 'getshareddocumentlist';

  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/${rootPath}` +
    `?path=${trimSplash(folderPath)}`;

  const body = {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search: searchText ? [{ field: 'name', value: searchText }] : [],
  };

  return makeRequest<DTO.GetFilesResponse>('POST', url, body);
};

const uploadFileToOwnedFolder = (
  folderPath: string,
  documentId: string | null,
  metadata: object | null,
  file: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/SaveDocument/` +
    `${documentId || trimSplash(folderPath)}`;

  const formData = new FormData();

  formData.append('file', file);

  if (metadata != null) {
    formData.append('metadata', JSON.stringify(metadata));
  }

  return uploadRequest('POST', url, formData, onUploadProgress, xhrRef);
};

const uploadFileToSharedFolder = (
  folderPath: string,
  documentId: string | null,
  metadata: object | null,
  file: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/SaveDocumentInSharedFolder/${documentId ||
    '-'}/${trimSplash(folderPath)}`;
  const formData = new FormData();

  formData.append('file', file);

  if (metadata != null) {
    formData.append('metadata', JSON.stringify(metadata));
  }

  return uploadRequest('POST', url, formData, onUploadProgress, xhrRef);
};

const updateOwnedFile = (documentId: string, metadata: object) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/UpdateDocument/${documentId}`;

  return makeRequest('POST', url, metadata);
};

const updateFolder = (folderId: string, metadata: object) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/UpdateFolder/${folderId}`;

  return makeRequest('POST', url, metadata);
};

const updateSharedFile = (
  documentId: string,
  metadata: object,
  folderPath: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/UpdateDocumentInSharedFolder/${documentId}/${trimSplash(
    folderPath
  )}`;

  return makeRequest('POST', url, metadata);
};

const addFolderToOwnedFolder = (absoluteFolderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/createfolder/${trimSplash(
    absoluteFolderPath
  )}`;

  return makeRequest<DTO.CreateNewFolderResponse>('POST', url);
};

const addFolderToSharedFolder = (absoluteFolderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/CreateFolderInSharedFolder/${trimSplash(
    absoluteFolderPath
  )}`;

  return makeRequest<DTO.CreateNewFolderResponse>('POST', url);
};

const updateOwnedFolder = () => {
  // TODO: Replace with makeRequest
  return Promise.resolve<{
    status: number;
    payload: {
      status: string;
      error_code: string;
      data: {};
    };
  }>({
    status: 0,
    payload: {
      status: 'Success',
      error_code: '',
      data: {},
    },
  });
};

const updateSharedFolder = () => {
  // TODO: Replace with makeRequest
  return Promise.resolve<{
    status: number;
    payload: {
      status: string;
      error_code: string;
      data: {};
    };
  }>({
    status: 0,
    payload: {
      status: 'Success',
      error_code: '',
      data: {},
    },
  });
};

const deleteOwnedFolder = (absoluteFolderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/deletefolder/${trimSplash(
    absoluteFolderPath
  )}`;

  return makeRequest('DELETE', url);
};

const deleteFile = (fileID: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/DeleteDocument/${fileID}`;

  return makeRequest('DELETE', url);
};

const getUserGroups = (folderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/getusergroups/${trimSplash(
    folderPath
  )}`;

  return makeRequest<DTO.GetUserGroupsResponse>('GET', url);
};

const shareFolder = (folderPath: string, groupName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/CreateShareFolder/${trimSplash(
    folderPath
  )}`;

  return makeRequest<DTO.GetUserGroupsResponse>('POST', url, groupName);
};

const unshareFolder = (folderPath: string, groupName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/DeleteShareFolder/${trimSplash(
    folderPath
  )}`;

  return makeRequest('DELETE', url, groupName);
};

const getOwnedFileDetails = (fileId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/GetDocument/${fileId}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const getSharedFileDetails = (folderPath: string, fileId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/GetSharedDocument/${fileId}/${trimSplash(
    folderPath
  )}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const uploadProductZipFile = (
  data: {
    file: File;
    path: string;
    refreshToken: string;
  },
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = '/api/filemanager/uploadfolder';

  const formData = new FormData();

  const { file, path, refreshToken } = data;

  formData.append('file', file);
  formData.append('path', path);
  formData.append('refreshToken', refreshToken);

  return uploadRequest<{ data: DTO.UploadProductZipFileResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const downloadFolder = (folderPath: string, data: DTO.DownloadFolderRequest) =>
  httpClient<{ data: string }>({
    method: 'POST',
    path: `/api/filemanager/downloadfolder/${folderPath}`,
    body: data,
  });

export const FileManagerService = {
  getFolders,
  getFiles,
  uploadFileToOwnedFolder,
  uploadFileToSharedFolder,
  addFolderToOwnedFolder,
  addFolderToSharedFolder,
  updateOwnedFolder,
  updateSharedFolder,
  getOwnedFileDetails,
  getSharedFileDetails,
  deleteOwnedFolder,
  deleteFile,
  updateOwnedFile,
  updateSharedFile,
  updateFolder,
  getUserGroups,
  shareFolder,
  unshareFolder,
  getFoldersAndFiles,
  uploadProductZipFile,
  downloadFolder,
};
