import queryString from 'query-string';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getProductEngines = (
  productName: string,
  request: DTO.GetProductEnginesRequest
) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines?`;

  url += queryString.stringify({
    page,
    pageSize,
    pathQuery: searchText,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
  });

  return makeRequest<DTO.GetProductEnginesResponse>('GET', url);
};

const updateProductEngine = (
  productName: string,
  serviceName: string,
  request: DTO.UpdateProductEnginePropsRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/updateengine/${serviceName}`;

  return makeRequest('PUT', url, request);
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string
) => {
  let url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/get/${serviceName}`;

  if (versionId) {
    url += `/${versionId}`;
  }

  return makeRequest<DTO.GetProductEngineDetailsResponse>('GET', url);
};

const deleteEngine = (productName: string, serviceName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/delete/${serviceName}`;

  return makeRequest('DELETE', url);
};

const uploadRegressionTestInput = (
  productName: string,
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/regression`;
  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<DTO.RegressionTestResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const uploadEngine = (
  productName: string,
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/add`;
  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<DTO.AddProductEngineResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const publishEngine = (
  productName: string,
  serviceName: string,
  data?: DTO.PublishEngineRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/publish/${serviceName}`;
  const formData = new FormData();

  if (data) {
    const {
      shouldUpdateVersion,
      releaseNote,
      effectiveEndDate,
      effectiveStartDate,
      masterReferenceDetail,
      masterReferenceApplied,
    } = data;

    formData.append('shouldUpdateVersion', shouldUpdateVersion.toString());
    formData.append('releaseNote', releaseNote || '');
    effectiveEndDate &&
      formData.append('effectiveEndDate', effectiveEndDate.toISOString());

    effectiveStartDate &&
      formData.append('effectiveStartDate', effectiveStartDate.toISOString());

    masterReferenceDetail &&
      formData.append(
        'masterReferenceDetail',
        JSON.stringify(masterReferenceDetail)
      );

    formData.append(
      'masterReferenceApplied',
      masterReferenceApplied.toString()
    );
  }

  return makeRequest<unknown>('PUT', url, formData);
};

const updateEngineDetail = (
  productName: string,
  serviceName: string,
  data: DTO.UpdateProductEngineDetailsRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/update/${serviceName}`;

  return makeRequest<unknown>('POST', url, data);
};

const getEngineDownloadUrl = (
  originalServiceName: string,
  documentVersionId: string,
  idToken: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/ExcelApi/Download/${documentVersionId}/${encodeURIComponent(
    originalServiceName
  )}?token=${encodeURIComponent(idToken)}`;
};

const getEncryptedEngineDownloadUrl = (
  originalServiceName: string,
  documentVersionId: string,
  idToken: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/ExcelApi/DownloadEncypted/${documentVersionId}/${encodeURIComponent(
    originalServiceName
  )}?token=${encodeURIComponent(idToken)}`;
};

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.GetProductEngineLogsRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/Product/${productName}/engines/${serviceName}/logs/${versionId}`;

  const {
    page = 1,
    pageSize = 11,
    sortBy = 'timestamp',
    sort = 'desc',
    searchText = '',
    startDate = '',
    endDate = '',
  } = request;

  const search: { field: string; value: string }[] = [];

  if (startDate) {
    search.push({ field: 'StartDate', value: startDate });
  }

  if (endDate) {
    search.push({ field: 'EndDate', value: endDate });
  }

  if (searchText) {
    search.push({ field: 'Search', value: searchText });
  }

  return makeRequest<DTO.GetProductEngineLogsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const executeProductEngine = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/Execute/${serviceName}`;
  return makeRequest<DTO.ExecuteProductEngineResponse>('POST', url, data);
};

const executeProductEngineInputs = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/ExecuteInputs/${serviceName}`;
  return makeRequest<DTO.ExecuteEngineInputsResponse>('POST', url, data);
};

const generateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/testcase/${versionId ||
    ''}`;

  return makeRequest<DTO.GenerateTestCasesResponse>('POST', url, data);
};

const getGeneratedTestCasesFile = (
  productName: string,
  serviceName: string,
  generatedTestCasesFile: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/testcase/${generatedTestCasesFile}`;

  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getRegressionTestResultFile = (
  productName: string,
  resultFile: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/regression/${resultFile}`;

  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getDownloadMatchesEngineLogsFileUrl = (
  productName: string,
  serviceName: string,
  versionId: string,
  startDate: string,
  endDate: string,
  userToken: string
) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  if (startDate && endDate) {
    return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/logs/DownloadAll/${versionId}/${timeZoneOffset}/${startDate}/${endDate}?token=${userToken}`;
  }
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/logs/DownloadAll/${versionId}/${timeZoneOffset}?token=${userToken}`;
};

const getDownloadEngineLogFileUrl = (
  productName: string,
  serviceName: string,
  startDate: string,
  endDate: string,
  engineCallId: string,
  userToken: string
) => {
  if (startDate && endDate) {
    return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${engineCallId}/${startDate}/${endDate}?token=${userToken}`;
  }
  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${engineCallId}?token=${userToken}`;
};

const getDownloadEngineUrl = (
  productName: string,
  serviceName: string,
  userToken: string,
  version: string,
  fileName: string,
  encrypted: boolean
) => {
  if (encrypted) {
    return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/DownloadEncrypted/${version}?fileName=${fileName}&token=${userToken}`;
  }

  return `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/Download/${version}?fileName=${fileName}&token=${userToken}`;
};

const getFormSpec = (productName: string, serviceName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/GetFormSpec`;

  return makeRequest<DTO.GetFormSpecResponse>('POST', url);
};

const getFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[],
  sectionId = ''
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/GetFormState/${sectionId}`;

  return makeRequest<DTO.GetFormStateResponse>('POST', url, fields);
};

const getTutorialFormSpec = (productName: string, serviceName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/GetFormSpecProductTutorial`;

  return makeRequest<DTO.GetFormSpecResponse>('POST', url);
};

const getTutorialFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[]
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/${serviceName}/GetFormStateProductTutorial`;

  return makeRequest<DTO.GetFormStateResponse>('POST', url, fields);
};

const getVersions = (productName: string, serviceName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/getversions/${serviceName}`;

  return makeRequest<DTO.GetEngineVersionsResponse>('GET', url);
};

const getSwaggerDownloadUrl = (
  request: DTO.DownloadSwaggerRequest,
  token: string
) => {
  const { productName, serviceName, category, version } = request;

  return `${EXCELENGINE_DOMAIN}/api/v1/Product/${productName}/engines/${serviceName}/DownloadSwagger/${category ||
    'Default'}/${version ? `${version}/` : ''}?token=${encodeURIComponent(
    token
  )}`;
};

const getExecuteOutputFile = (
  productName: string,
  engine: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/Downloadoutput/${engine}`;
  return makeRequest<DTO.DownloadExecuteOutputResponse>('POST', url, data);
};

const downloadExecuteOutputFile = (url: string) => {
  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getCUrlDownloadUrl = (
  request: DTO.DownloadCUrlRequest,
  token: string
) => {
  const { productName, serviceName, category, version } = request;

  return `${EXCELENGINE_DOMAIN}/api/v1/Product/${productName}/engines/${serviceName}/DownloadCurl/${category ||
    'Default'}/${version ? `${version}/` : ''}?token=${encodeURIComponent(
    token
  )}`;
};

const getPerformanceMetrics = (
  productName: string,
  data: DTO.PerformanceMetricsRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/metrics/get`;

  data.Engines.forEach(engine => delete engine.Revision);

  return makeRequest<DTO.EnginePerformanceMetricsResponse>('POST', url, data);
};

const getSurfixUrl = (productName: string, engineName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/proxy/GetProxyRule/${productName}/${engineName}`;

  return makeRequest<DTO.GetProxyUrlResponse>('GET', url);
};

const saveProxyUrl = (data: DTO.SaveProxyUrlRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/proxy/SaveProxyRule`;

  return makeRequest<DTO.SaveProxyUrlResponse>('POST', url, data);
};

const deleteProxyUrl = (proxyUrl: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/proxy/DeleteProxyRule/${proxyUrl}`;

  return makeRequest('DELETE', url);
};

const restoreVersion = (
  productName: string,
  engineName: string,
  versionId: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/restoreversion/${engineName}/${versionId}`;

  return makeRequest<{
    data: string;
  }>('POST', url);
};

export const EngineService = {
  getProductEngines,
  getProductEngineDetails,
  updateProductEngine,
  updateEngineDetail,
  deleteEngine,
  getEngineDownloadUrl,
  getEncryptedEngineDownloadUrl,
  uploadEngine,
  uploadRegressionTestInput,
  generateTestCases,
  getGeneratedTestCasesFile,
  publishEngine,
  getProductEngineLogs,
  executeProductEngine,
  executeProductEngineInputs,
  getRegressionTestResultFile,
  getDownloadMatchesEngineLogsFileUrl,
  getDownloadEngineLogFileUrl,
  getDownloadEngineUrl,
  getFormSpec,
  getFormState,
  getSwaggerDownloadUrl,
  getVersions,
  getPerformanceMetrics,
  getTutorialFormSpec,
  getTutorialFormState,
  getSurfixUrl,
  saveProxyUrl,
  deleteProxyUrl,
  getCUrlDownloadUrl,
  getExecuteOutputFile,
  downloadExecuteOutputFile,
  restoreVersion,
};
