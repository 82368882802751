import queryString from 'query-string';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getMasterReferences = (request: DTO.GetMasterReferencesRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  let url = `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager?`;

  url += queryString.stringify({
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    searchText,
  });

  return makeRequest<DTO.GetMasterReferencesResponse>('GET', url);
};

const upload = (
  file: File,
  documentId: string | null | undefined,
  request: DTO.UpdateMasterRefRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const { effectiveFromDate, effectiveToDate, metadata } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager/SaveMasterReference/${documentId ||
    '-'}`;
  const formData = new FormData();

  file && formData.append('file', file);
  metadata && formData.append('metadata', JSON.stringify({}));
  effectiveFromDate && formData.append('effectiveFromDate', effectiveFromDate);
  effectiveToDate && formData.append('effectiveToDate', effectiveToDate);

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getDownloadReferenceFileUrl = (documentName: string, token: string) =>
  `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager/Download/${documentName}?token=${encodeURIComponent(
    token
  )}`;

const deleteDocument = (documentId: string, fileName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager/${documentId}/${fileName}`;

  return makeRequest('DELETE', url);
};

const syncData = (request: DTO.SyncDataRequest) => {
  const { productName, serviceName, version } = request;
  const url = `${EXCELENGINE_DOMAIN}/api/v1/product/${productName}/engines/syncdata/${serviceName}/${version}`;
  const formData = new FormData();
  request?.effectiveStartDate &&
    formData.append(
      'effectiveStartDate',
      request.effectiveStartDate.toISOString()
    );
  request?.masterReferenceDetail &&
    formData.append(
      'masterReferenceDetail',
      JSON.stringify(request.masterReferenceDetail)
    );
  request?.effectiveEndDate &&
    formData.append('effectiveEndDate', request.effectiveEndDate.toISOString());

  return makeRequest<DTO.SyncDataResponse>('POST', url, formData);
};

const updateMetadata = (
  documentId: string,
  request: DTO.UpdateMasterRefRequest
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager/${documentId}`;

  return makeRequest<DTO.SyncDataResponse>('PUT', url, request);
};

const getDetails = (documentId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterReferenceManager/${documentId}`;

  return makeRequest<DTO.MasterReferenceDetails>('GET', url);
};

const getMasterAPIdefFile = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinitionManager?`;

  return makeRequest<DTO.GetMasterAPIdefFileResponse>('GET', url);
};

const getDownloadMasterAPIDefFileUrl = (documentName: string, token: string) =>
  `${EXCELENGINE_DOMAIN}/api/MasterApiDefinitionManager/Download/${documentName}?token=${encodeURIComponent(
    token
  )}`;

const uploadMasterAPIDef = (
  file: File,
  documentId: string | null | undefined,
  request: DTO.UpdateMasterRefRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const { metadata } = request;

  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinitionManager`;
  const formData = new FormData();

  formData.append('file', file);
  documentId && formData.append('id', documentId);
  metadata && formData.append('metadata', JSON.stringify({}));

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const deleteMasterAPIDef = (documentId: string, fileName: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/MasterApiDefinitionManager/${documentId}/${fileName}`;

  return makeRequest('DELETE', url);
};

export const MasterReferenceServices = {
  getMasterReferences,
  upload,
  syncData,
  getDownloadReferenceFileUrl,
  deleteDocument,
  updateMetadata,
  getDetails,
  getMasterAPIdefFile,
  getDownloadMasterAPIDefFileUrl,
  uploadMasterAPIDef,
  deleteMasterAPIDef,
};
