import {
  ProductsConstants,
  EngineConstants,
  ShareProductConstants,
  MasterReferenceConstants,
  BackgroundJobsConstants,
} from '../constants';
import { AppAction } from '../actions/types';
import { arrayReplaceFirst } from '../helpers';

export const initialState: STATES.ProductsState = {
  isLoading: false,
  products: [],
  filterCategory: undefined,
  filterStatus: undefined,
  filterFavorite: false,
  page: 1,
  totalPages: 0,
  pageSize: 14,
  searchText: '',
  modal: 'None',
  selectedProductName: '',
  selectedProduct: null,
  modalLoading: false,
  coverImageUploadProgress: 0,
  coverImageUploadError: null,
  deletingProducts: {},
  refreshTrigger: 0,
  cachedFileUrls: {},
  cachedFileData: {},
  clonedProductName: null,
  productShared: false,
  isLoadingProductDetail: false,
  isUploadingProduct: false,
  uploadProgress: 0,
  uploadError: null,
  uploadXhrRef: null,
  uploadResult: null,
};

export const productsState = (
  state = initialState,
  action: AppAction
): STATES.ProductsState => {
  switch (action.type) {
    case ProductsConstants.GET_PRODUCTS_REQUEST: {
      const {
        page,
        searchText = '',
        filterCategory,
        filterFavorite,
        filterStatus,
        sort,
        sortBy,
      } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        filterCategory,
        filterFavorite: !!filterFavorite,
        filterStatus,
        sort,
        sortBy,
      };
    }
    case ProductsConstants.GET_PRODUCTS_SUCCESS: {
      const {
        products,
        total,
        page,
        searchText = '',
        filterCategory,
        filterFavorite = false,
        filterStatus,
        sortBy,
        sort,
      } = action.payload;

      if (
        searchText !== state.searchText ||
        page !== state.page ||
        filterCategory !== state.filterCategory ||
        filterFavorite !== state.filterFavorite ||
        filterStatus !== state.filterStatus ||
        sort !== state.sort ||
        sortBy !== state.sortBy
      ) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        products: page === 1 ? products : [...state.products, ...products],
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
      };
    }
    case ProductsConstants.GET_PRODUCTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ProductsConstants.NEW_PRODUCT: {
      return {
        ...state,
        selectedProduct: null,
        selectedProductName: '',
        modal: 'New',
      };
    }
    case ProductsConstants.UPLOAD_DOCUMENT_CANCEL:
    case ProductsConstants.UPLOAD_ENGINE_CANCEL:
    case ProductsConstants.NEW_PRODUCT_CANCEL:
    case ProductsConstants.CLONE_PRODUCT_CANCEL:
    case ProductsConstants.EDIT_PRODUCT_CANCEL: {
      return {
        ...state,
        modal: 'None',
      };
    }
    case ProductsConstants.CLONE_PRODUCT: {
      const { product } = action.payload;
      return {
        ...state,
        selectedProduct: product,
        modal: 'Clone',
      };
    }
    case ProductsConstants.UPDATE_PRODUCT_REQUEST:
    case EngineConstants.UPDATE_ENGINE_PROPS_REQUEST:
    case ProductsConstants.SHARE_PRODUCT_REQUEST:
    case EngineConstants.PUBLISH_UPLOADED_ENGINE:
    case ProductsConstants.CREATE_PRODUCT_REQUEST: {
      return {
        ...state,
        modalLoading: true,
      };
    }
    case ProductsConstants.UPDATE_PRODUCT_FAILURE:
    case EngineConstants.UPDATE_ENGINE_PROPS_FAILURE:
    case EngineConstants.PUBLISH_UPLOADED_ENGINE_FAILURE:
    case ProductsConstants.SHARE_PRODUCT_FAILURE:
    case ProductsConstants.CREATE_PRODUCT_FAILURE:
    case ProductsConstants.CLONE_PRODUCT_FAILURE: {
      return {
        ...state,
        modalLoading: false,
      };
    }
    case ProductsConstants.CREATE_PRODUCT_SUCCESS: {
      const { selectedProductName } = action.payload;

      return {
        ...state,
        selectedProductName,
        modalLoading: false,
        modal: 'UploadEngine',
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ProductsConstants.CLONE_PRODUCT_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        clonedProductName: null,
      };
    }
    case ProductsConstants.CLONE_PRODUCT_SUCCESS: {
      const { newProductName } = action.payload;

      return {
        ...state,
        modalLoading: false,
        modal: 'None',
        clonedProductName: newProductName,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ProductsConstants.UPDATE_PRODUCT_SUCCESS: {
      const { changes, selectedProductName } = action.payload;

      let newState: STATES.ProductsState = {
        ...state,
        modal: 'None',
        modalLoading: false,
      };

      const product = state.products.find(
        x => x.id === selectedProductName || x.name === selectedProductName
      );

      if (product) {
        newState = {
          ...newState,
          products: arrayReplaceFirst(
            newState.products,
            x => x.id === selectedProductName || x.name === selectedProductName,
            {
              ...product,
              ...changes,
            }
          ),
        };
      }

      if (
        newState.selectedProduct &&
        (newState.selectedProduct.id === selectedProductName ||
          newState.selectedProduct.name === selectedProductName)
      ) {
        newState = {
          ...newState,
          selectedProduct: {
            ...newState.selectedProduct,
            ...changes,
          },
        };
      }

      return newState;
    }
    case EngineConstants.SHOW_UPDATE_CONFIRM: {
      return {
        ...state,
        modal: 'UpdateEngineConfirmForm',
      };
    }
    case MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS:
    case EngineConstants.CANCEL_UPDATE_CONFIRM: {
      return {
        ...state,
        modalLoading: false,
        modal: 'None',
      };
    }
    case ProductsConstants.UPLOAD_ENGINE: {
      return {
        ...state,
        modal: 'UploadEngine',
      };
    }

    case EngineConstants.CANCEL_PUBLISH_ENGINE:
    case EngineConstants.PUBLISH_UPLOADED_ENGINE_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        modal:
          action.payload && action.payload.keepOpenModal ? state.modal : 'None',
      };
    }

    case ProductsConstants.COVER_IMAGE_UPLOAD_START: {
      return {
        ...state,
        coverImageUploadProgress: 5,
        coverImageUploadError: null,
      };
    }

    case ProductsConstants.COVER_IMAGE_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        coverImageUploadProgress: uploadProgress,
      };
    }

    case ProductsConstants.COVER_IMAGE_SUCCESS: {
      const { productId, file, blobUrl } = action.payload;

      let oldCoverImagePath: string | undefined;
      let nextState: STATES.ProductsState | null = null;

      if (state.selectedProduct && state.selectedProduct.id === productId) {
        oldCoverImagePath = state.selectedProduct.coverImagePath;

        // In case product doens't have coverImagePath before
        if (!oldCoverImagePath) {
          oldCoverImagePath = blobUrl;
          nextState = {
            ...state,
            selectedProduct: {
              ...state.selectedProduct,
              coverImagePath: blobUrl,
            },
            products: arrayReplaceFirst(
              state.products,
              x => x.id === productId,
              product => ({
                ...product,
                coverImagePath: blobUrl,
              })
            ),
          };
        }
      } else {
        const product = state.products.find(p => p.id === productId);

        if (product) {
          oldCoverImagePath = product.coverImagePath;

          // In case product doens't have coverImagePath before
          if (!oldCoverImagePath) {
            oldCoverImagePath = blobUrl;
            nextState = {
              ...state,
              products: arrayReplaceFirst(state.products, x => x === product, {
                ...product,
                coverImagePath: blobUrl,
              }),
            };
          }
        }
      }

      const cachedFileData = { ...state.cachedFileData };
      const cachedFileUrls = { ...state.cachedFileUrls };

      if (oldCoverImagePath) {
        cachedFileData[oldCoverImagePath] = file;
        cachedFileUrls[oldCoverImagePath] = blobUrl;
      }

      return {
        ...(nextState || state),
        cachedFileData,
        cachedFileUrls,
        coverImageUploadProgress: 100,
      };
    }

    case ProductsConstants.COVER_IMAGE_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        coverImageUploadError: error,
      };
    }

    case ProductsConstants.FAVORITE_PRODUCT_REQUEST: {
      const { productId, favorite } = action.payload;

      return {
        ...state,
        products: arrayReplaceFirst(
          state.products,
          product => product.id === productId,
          product => ({ ...product, isStarred: favorite })
        ),
      };
    }

    case ProductsConstants.EDIT_PRODUCT: {
      const { product, summary } = action.payload;

      return {
        ...state,
        selectedProduct: product,
        modal: summary ? 'EditProductSummary' : 'EditProduct',
      };
    }
    case ProductsConstants.EDIT_COVER_IMAGE: {
      const { product } = action.payload;

      return {
        ...state,
        selectedProduct: product,
        coverImageUploadError: null,
        coverImageUploadProgress: 0,
        modal: 'EditCoverImage',
      };
    }
    case ProductsConstants.COVER_IMAGE_UPLOAD_RESET: {
      return {
        ...state,
        coverImageUploadError: null,
        coverImageUploadProgress: 0,
      };
    }
    case ProductsConstants.EDIT_COVER_IMAGE_CANCEL: {
      return {
        ...state,
        coverImageUploadError: null,
        coverImageUploadProgress: 0,
        modal: 'None',
      };
    }
    case ProductsConstants.COVER_IMAGE_GET_URL_SUCCESS: {
      const { imagePath, imageUrl, imageData } = action.payload;

      const cachedFileUrls = {
        ...state.cachedFileUrls,
        [imagePath]: imageUrl,
      };

      const cachedFileData = {
        ...state.cachedFileData,
        [imagePath]: imageData,
      };

      return {
        ...state,
        cachedFileUrls,
        cachedFileData,
      };
    }
    case ShareProductConstants.SHARE_PRODUCT_DETAIL_REQUEST:
    case ProductsConstants.GET_PRODUCT_DETAIL_REQUEST: {
      const { productName } = action.payload;

      const product = state.products.find(x => x.name === productName);

      return {
        ...state,
        selectedProductName: productName,
        selectedProduct: product || state.selectedProduct,
        isLoadingProductDetail: true,
      };
    }
    case ShareProductConstants.SHARE_PRODUCT_DETAIL_SUCCESS:
    case ProductsConstants.GET_PRODUCT_DETAIL_SUCCESS: {
      const { product } = action.payload;

      if (
        !state.selectedProduct ||
        (state.selectedProduct && state.selectedProduct.id === product.id)
      ) {
        return {
          ...state,
          isLoadingProductDetail: false,
          selectedProduct: product,
          products: arrayReplaceFirst(
            state.products,
            x => x.id === product.id,
            product
          ),
        };
      }

      return state;
    }
    case ProductsConstants.CHANGE_PRODUCT_STATUS_REQUEST: {
      const { productId, status } = action.payload;

      if (state.selectedProduct && state.selectedProduct.id === productId) {
        const selectedProduct = {
          ...state.selectedProduct,
          status,
        };

        return {
          ...state,
          selectedProduct,
          products: arrayReplaceFirst(
            state.products,
            x => x.id === productId,
            selectedProduct
          ),
        };
      }

      return state;
    }
    case ProductsConstants.CHANGE_PRODUCT_STATUS_SUCCESS: {
      const { productId, status } = action.payload;

      return {
        ...state,
        selectedProduct:
          state.selectedProduct && state.selectedProduct.id === productId
            ? { ...state.selectedProduct, status }
            : state.selectedProduct,
        products: arrayReplaceFirst(
          state.products,
          x => x.id === productId,
          product => ({
            ...product,
            status,
          })
        ),
      };
    }
    case ProductsConstants.CLEAN_SELETED_PRODUCT: {
      return {
        ...state,
        selectedProduct: null,
        clonedProductName: null,
      };
    }
    case ProductsConstants.UPLOAD_DOCUMENT: {
      return {
        ...state,
        modal: 'UploadDocument',
      };
    }
    case ProductsConstants.SHARE_PRODUCT_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        productShared: true,
      };
    }

    case ProductsConstants.UPLOAD_PRODUCT_START: {
      return {
        ...state,
        isUploadingProduct: true,
        uploadProgress: 5,
        uploadError: null,
      };
    }

    case ProductsConstants.UPLOAD_PRODUCT_XHR_REF: {
      const { xhrRef } = action.payload;

      return {
        ...state,
        uploadXhrRef: xhrRef,
      };
    }
    case ProductsConstants.UPLOAD_PRODUCT_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case ProductsConstants.UPLOAD_PRODUCT_SUCCESS: {
      const { result } = action.payload;

      return {
        ...state,
        isUploadingProduct: false,
        uploadProgress: 100,
        uploadResult: result,
      };
    }
    case ProductsConstants.UPLOAD_PRODUCT_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isUploadingProduct: false,
        uploadError: error,
      };
    }
    case ProductsConstants.UPLOAD_PRODUCT_RESET: {
      return {
        ...state,
        isUploadingProduct: false,
        uploadProgress: 0,
        uploadError: null,
        uploadXhrRef: null,
        uploadResult: null,
      };
    }

    case BackgroundJobsConstants.GET_BGJOBS_SUCCESS: {
      const { reloadProductFolderList } = action.payload;

      if (!reloadProductFolderList) {
        return state;
      }

      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    default:
      return state;
  }
};
