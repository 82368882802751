import { FileManagerConstants } from '../constants';
import { FileManagerAction, FileManagerThunkAction } from './types';
import { AlertActions } from './alert.actions';
import { FileManagerService } from '../services';
import { ApiError } from '../helpers';

const getFoldersAndFiles = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  foldersAndFilesRequest: DTO.GetFoldersAndFilesRequest
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_REQUEST,
      payload: {
        root,
        folderPath,
        foldersAndFilesRequest,
      },
    });

    const { status, payload } = await FileManagerService.getFoldersAndFiles(
      root,
      folderPath,
      foldersAndFilesRequest
    );

    if (
      status !== 200 ||
      !['Success', 'OK', null].includes(payload.status || null)
    ) {
      throw new ApiError(payload);
    }

    const {
      folders,
      documents_page: { documents },
    } = payload;

    dispatch({
      type: FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_SUCCESS,
      payload: {
        root,
        folderPath,
        foldersAndFilesRequest,
        folders,
        files: documents,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_FAILURE,
      payload: {
        root,
        folderPath,
        foldersAndFilesRequest,
        error: msg,
      },
    });
  }
};

const getFolders = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  foldersRequest: DTO.GetFoldersRequest
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.GET_FOLDERS_IN_PARENT_REQUEST,
      payload: {
        root,
        folderPath,
        foldersRequest,
      },
    });

    const { status, payload } = await FileManagerService.getFolders(
      root,
      folderPath,
      foldersRequest
    );

    if (
      status !== 200 ||
      !['Success', 'OK', null].includes(payload.status || null)
    ) {
      throw new ApiError(payload);
    }

    const {
      folders_page: { folders, count },
    } = payload;

    dispatch({
      type: FileManagerConstants.GET_FOLDERS_IN_PARENT_SUCCESS,
      payload: {
        root,
        folderPath,
        foldersRequest,
        folders,
        total: count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FOLDERS_IN_PARENT_FAILURE,
      payload: {
        root,
        folderPath,
        foldersRequest,
        error: msg,
      },
    });
  }
};

/**
 * @param documentId use this for uploading an update of document
 */
const uploadFile = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  file: File,
  metadata: object | null,
  documentId: string | null
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    const uploadService =
      root === 'home'
        ? FileManagerService.uploadFileToOwnedFolder
        : FileManagerService.uploadFileToSharedFolder;

    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_START,
    });

    const { status, payload } = await uploadService(
      folderPath,
      documentId,
      metadata,
      file,
      uploadProgress => {
        if (uploadProgress === 100) return;

        // 5% for server processing time
        dispatch({
          type: FileManagerConstants.UPLOAD_FILE_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: FileManagerConstants.UPLOAD_FILE_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_SUCCESS,
      payload: { root },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPLOAD_FILE_FAILURE,
      payload: { error: msg },
    });

    return false;
  }
};

const updateFolderMetadata = (
  root: DTO.FileManagerRootType,
  folderId: string,
  metadata: object
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_REQUEST,
    });

    const { status, payload } = await FileManagerService.updateFolder(
      folderId,
      metadata
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_SUCCESS,
      payload: {
        root,
        folderId,
        metadata,
      },
    });

    return true;
  } catch (error) {
    await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_FAILURE,
    });

    return false;
  }
};

const updateMetadata = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  metadata: object,
  fileId: string
): FileManagerThunkAction<boolean> => async dispatch => {
  try {
    const updateFile =
      root === 'home'
        ? FileManagerService.updateOwnedFile
        : FileManagerService.updateSharedFile;

    dispatch({
      type: FileManagerConstants.UPDATE_FILE_REQUEST,
      payload: {
        root,
        folderPath,
        fileId,
      },
    });

    const { status, payload } = await updateFile(fileId, metadata, folderPath);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPDATE_FILE_SUCCESS,
      payload: {
        root,
        folderPath,
        fileId,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPDATE_FILE_FAILURE,
      payload: {
        root,
        folderPath,
        fileId,
        error: msg,
      },
    });

    return false;
  }
};

const resetUploadFile = (): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_RESET,
});

const openUploadModal = (
  root: DTO.FileManagerRootType,
  folderPath: string
): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_OPEN,
  payload: {
    root,
    folderPath,
  },
});

const closeUploadModal = (): FileManagerAction => ({
  type: FileManagerConstants.UPLOAD_FILE_CLOSE,
});

const addFolder = (root: DTO.FileManagerRootType): FileManagerAction => ({
  type: FileManagerConstants.ADD_FOLDER,
  payload: {
    root,
    timestamp: new Date().getTime().toFixed(0),
  },
});

const saveNewFolder = (
  root: DTO.FileManagerRootType,
  parentFolderPath: string,
  folderName: string,
  folderTimestamp: string
): FileManagerThunkAction => async dispatch => {
  try {
    const addFolderService =
      root === 'home'
        ? FileManagerService.addFolderToOwnedFolder
        : FileManagerService.addFolderToSharedFolder;

    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_REQUEST,
      payload: {
        root,
        folderTimestamp,
      },
    });

    const { status, payload } = await addFolderService(
      `${parentFolderPath}${folderName}`
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_SUCCESS,
      payload: {
        root,
        folderTimestamp,
        folder: {
          id: payload.folder_id,
          name: folderName,
          path: payload.folder_path,
          metadata: {},
          created_at: new Date().toISOString(),
        },
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.SAVE_NEW_FOLDER_FAILURE,
      payload: {
        root,
        folderTimestamp,
        error: msg,
      },
    });
  }
};

const cancelNewFolder = (
  root: DTO.FileManagerRootType,
  folderName: string
): FileManagerAction => ({
  type: FileManagerConstants.CANCEL_NEW_FOLDER,
  payload: {
    root,
    folderName,
  },
});

const setWaitingSelectedFolderDetail = (
  root: DTO.FileManagerRootType,
  folderPath: string
): FileManagerAction => ({
  type: FileManagerConstants.SET_WAITING_SELECTED_FOLDER_DETAILS,
  payload: {
    root,
    folderPath,
  },
});

const selectFolderToEdit = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  folder: DTO.DSFolder
): FileManagerAction => ({
  type: FileManagerConstants.GET_FOLDER_DETAILS_SUCCESS,
  payload: {
    root,
    folderPath,
    folder,
  },
});

const selectFile = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  fileId: string,
  file: DTO.DSFile
): FileManagerAction => ({
  type: FileManagerConstants.GET_FILE_DETAILS_SUCCESS,
  payload: {
    root,
    fileId,
    folderPath,
    file,
  },
});

const getFileDetails = (
  root: DTO.FileManagerRootType,
  folderPath: string,
  fileId: string
): FileManagerThunkAction => async dispatch => {
  try {
    const getFileDetailsService =
      root === 'home'
        ? FileManagerService.getOwnedFileDetails
        : FileManagerService.getSharedFileDetails;

    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_REQUEST,
      payload: {
        root,
        folderPath,
        fileId,
      },
    });

    const { status, payload } = await getFileDetailsService(folderPath, fileId);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_SUCCESS,
      payload: {
        root,
        folderPath,
        fileId,
        file: payload,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.GET_FILE_DETAILS_FAILURE,
      payload: {
        root,
        folderPath,
        fileId,
        error: msg,
      },
    });
  }
};

const deleteFolder = (
  folderName: string,
  absoluteFolderPath: string,
  isSubFolder: boolean
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_REQUEST,
      payload: {
        folderName,
        isSubFolder,
      },
    });

    const { status, payload } = await FileManagerService.deleteOwnedFolder(
      absoluteFolderPath
    );

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_SUCCESS,
      payload: {
        folderName,
        isSubFolder,
      },
    });

    dispatch(
      AlertActions.success('FileManagerFolders.menu.delete.success', {
        folderName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.DELETE_FOLDER_FAILURE,
      payload: {
        folderName,
        error: msg,
      },
    });
  }
};

const downloadFile = (file: DTO.DSFile): FileManagerThunkAction => () => {
  window.location.href = file.latest_version_url;
};

const deleteFile = (
  root: DTO.FileManagerRootType,
  fileName: string,
  fileId: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.DELETE_FILE_REQUEST,
      payload: { fileId },
    });

    const { status, payload } = await FileManagerService.deleteFile(fileId);

    if (status !== 200 || !['Success', 'OK'].includes(payload.status || '')) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.DELETE_FILE_SUCCESS,
      payload: { fileId, root },
    });

    dispatch(
      AlertActions.success('FileManagerFiles.menu.delete.success', {
        fileName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.DELETE_FILE_FAILURE,
      payload: {
        fileId,
        error: msg,
      },
    });
  }
};

const getFolderShareState = (
  root: DTO.FileManagerRootType,
  folderPath: string
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.FOLDER_SHARE_STATE_REQUEST,
      payload: {
        root,
        folderPath,
      },
    });

    const { status, payload } = await FileManagerService.getUserGroups(
      folderPath
    );

    if (
      status !== 200 ||
      !['Success', 'OK', 'Ok'].includes(payload.status || '')
    ) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.FOLDER_SHARE_STATE_SUCCESS,
      payload: {
        folderPath,
        root,
        groups: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.FOLDER_SHARE_STATE_FAILURE,
      payload: {
        folderPath,
        root,
        error: msg,
      },
    });
  }
};

const resetFolderShareState = (
  root: DTO.FileManagerRootType
): FileManagerAction => ({
  type: FileManagerConstants.FOLDER_SHARE_STATE_RESET,
  payload: { root },
});

const updateFolderShareStatus = (
  root: DTO.FileManagerRootType,
  sharingFolderPath: string,
  groupName: string,
  shared: boolean
): FileManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_SHARE_REQUEST,
      payload: {
        root,
        sharingFolderPath,
        groupName,
      },
    });

    const service = shared
      ? FileManagerService.shareFolder
      : FileManagerService.unshareFolder;

    const { status, payload } = await service(sharingFolderPath, groupName);

    if (
      status !== 200 ||
      !['Success', 'OK', 'Ok'].includes(payload.status || '')
    ) {
      throw new ApiError(payload);
    }

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_SHARE_SUCCESS,
      payload: {
        root,
        sharingFolderPath,
        groupName,
        shared,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: FileManagerConstants.UPDATE_FOLDER_SHARE_FAILURE,
      payload: {
        root,
        sharingFolderPath,
        groupName,
        error: msg,
      },
    });
  }
};

export const FileManagerActions = {
  getFolders,
  addFolder,
  saveNewFolder,
  openUploadModal,
  closeUploadModal,
  uploadFile,
  cancelNewFolder,
  deleteFolder,
  getFileDetails,
  selectFile,
  resetUploadFile,
  deleteFile,
  downloadFile,
  updateMetadata,
  selectFolderToEdit,
  resetFolderShareState,
  getFolderShareState,
  updateFolderShareStatus,
  setWaitingSelectedFolderDetail,
  updateFolderMetadata,
  getFoldersAndFiles,
};
