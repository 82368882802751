import { MasterReferenceConstants } from '../constants';
import { ApiError } from '../helpers';
import { MasterReferenceServices } from '../services';
import { AlertActions } from './alert.actions';
import { MasterReferenceThunkAction } from './types';

const getMasterAPIDefinition = (): MasterReferenceThunkAction => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_REQUEST,
    });

    const {
      payload,
      status,
    } = await MasterReferenceServices.getMasterAPIdefFile();

    if (status !== 200 || payload.status === 'Error') {
      if (
        payload.status === 'Error' &&
        payload.errorCode === 'MASTER_API_DEFINITION_NOT_EXISTS'
      ) {
        dispatch({
          type: MasterReferenceConstants.GET_MASTER_API_SUCCESS,
          payload: { file: null },
        });

        return;
      }

      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_SUCCESS,
      payload: { file: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_FAILURE,
      payload: { error: msg },
    });
  }
};

const getMasterReferences = (
  docRequest: DTO.GetMasterReferencesRequest
): MasterReferenceThunkAction => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_REQUEST,
      payload: { docRequest },
    });

    const {
      payload,
      status,
    } = await MasterReferenceServices.getMasterReferences(docRequest);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const {
      documents_page: { documents, count: total },
    } = payload;

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_SUCCESS,
      payload: {
        documents,
        total,
        docRequest,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_FAILURE,
      payload: { error: msg },
    });
  }
};

const upload = (
  file: File,
  documentId: string | null | undefined,
  category: 'api' | 'normal',
  request: DTO.UpdateMasterRefRequest
): MasterReferenceThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_START,
    });

    const uploadService =
      category === 'normal'
        ? MasterReferenceServices.upload
        : MasterReferenceServices.uploadMasterAPIDef;

    const { status, payload } = await uploadService(
      file,
      documentId,
      request,
      uploadProgress => {
        if (uploadProgress === 100) return;

        // 5% for server processing time
        dispatch({
          type: MasterReferenceConstants.MASTER_REF_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: MasterReferenceConstants.MASTER_REF_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_SUCCESS,
      payload: {
        engineReferences: payload.data || [],
        fileName: file.name,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_ERROR,
      payload: { error: msg },
    });

    return false;
  }
};

const downloadReferenceFile = (
  documentName: string
): MasterReferenceThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  window.location.href = MasterReferenceServices.getDownloadReferenceFileUrl(
    documentName,
    id_token
  );
};

const downloadAPIDefFile = (
  documentName: string
): MasterReferenceThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  window.location.href = MasterReferenceServices.getDownloadMasterAPIDefFileUrl(
    documentName,
    id_token
  );
};

const deleteDocument = (
  document: DTO.MasterReference
): MasterReferenceThunkAction => async dispatch => {
  try {
    const { id, name } = document;
    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_REQUEST,
      payload: {
        id,
        name,
      },
    });

    const { status, payload } = await MasterReferenceServices.deleteDocument(
      id,
      name
    );

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_SUCCESS,
      payload: {
        id,
        name,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_FAILURE,
      payload: { error: msg },
    });
  }
};

const deleteAPIDefDocument = (
  document: DTO.MasterAPIDefinitionFile
): MasterReferenceThunkAction => async dispatch => {
  try {
    const { id, displayName } = document;

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_REQUEST,
      payload: {
        id,
        name: displayName,
      },
    });

    const {
      status,
      payload,
    } = await MasterReferenceServices.deleteMasterAPIDef(id, displayName);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_SUCCESS,
      payload: {
        id,
        name: displayName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_FAILURE,
      payload: { error: msg },
    });
  }
};

const updateMetadata = (
  documentId: string,
  data: DTO.UpdateMasterRefRequest
): MasterReferenceThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.UPDATE_MASTER_REF_REQUEST,
    });

    const { status, payload } = await MasterReferenceServices.updateMetadata(
      documentId,
      data
    );

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.UPDATE_MASTER_REF_SUCCESS,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.UPDATE_MASTER_REF_FAILURE,
      payload: { error: msg },
    });

    return false;
  }
};

const getDetails = (
  documentId: string
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_REQUEST,
    payload: { documentId },
  });

  try {
    const { status, payload } = await MasterReferenceServices.getDetails(
      documentId
    );

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    const {
      id,
      name,
      path,
      metadata,
      updated,
      versions,
      latest_version_id,
      latest_version_url,
    } = payload;

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_SUCCESS,
      payload: {
        document: {
          id,
          latest_version_id,
          latest_version_url,
          metadata,
          name,
          path,
          created_at: updated,
          updated_at: updated,
          versions,
        },
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_FAILURE,
      payload: { error: msg },
    });
  }
};

const syncData = (
  data: DTO.SyncDataRequest
): MasterReferenceThunkAction<string | null> => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_REQUEST,
    });

    const { status, payload } = await MasterReferenceServices.syncData(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS,
      payload: { version: data.version },
    });

    payload.message && dispatch(AlertActions.success(payload.message));

    return payload.data;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_FAILURE,
      payload: { error: msg },
    });

    return null;
  }
};

const selectDoc = (document: DTO.MasterReference) => ({
  type: MasterReferenceConstants.SELECT_MASTER_REF,
  payload: { document },
});

const resetUpload = () => ({
  type: MasterReferenceConstants.MASTER_REF_UPLOAD_RESET,
});

export const MasterReferenceActions = {
  selectDoc,
  getMasterReferences,
  upload,
  downloadReferenceFile,
  deleteDocument,
  updateMetadata,
  getDetails,
  syncData,
  resetUpload,
  getMasterAPIDefinition,
  downloadAPIDefFile,
  deleteAPIDefDocument,
};
