import { BackgroundJobsConstants, ProductsConstants } from '../constants';
import { ApiError } from '../helpers';
import { ProductService, FileManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { createAsyncThunk } from './helpers';
import { AppAction, ProductsAction, ProductsThunkAction } from './types';

const uploadCoverImage = (
  productId: string,
  file: File | Blob
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.COVER_IMAGE_UPLOAD_START });

    const { status, payload } = await ProductService.uploadCoverImage(
      productId,
      file,
      uploadProgress => {
        if (uploadProgress === 100) return;

        // 5% for server processing time
        dispatch({
          type: ProductsConstants.COVER_IMAGE_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.COVER_IMAGE_SUCCESS,
      payload: {
        productId,
        blobUrl: URL.createObjectURL(file),
        file,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.COVER_IMAGE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getAnalysisReport = (
  product: string,
  file: string
): ProductsThunkAction<string> => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return '';

  const { id_token } = userAuth;

  return ProductService.getAnalysisReportUrl(product, file, id_token);
};

const getProducts = (
  data: DTO.GetProductsRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.GET_PRODUCTS_REQUEST,
      payload: { ...data },
    });

    const { payload, status } = await ProductService.getProducts(data);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.GET_PRODUCTS_SUCCESS,
      payload: {
        ...data,
        products: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_PRODUCTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const newProduct = (): ProductsAction => ({
  type: ProductsConstants.NEW_PRODUCT,
});

const cancelNewProduct = (): ProductsAction => ({
  type: ProductsConstants.NEW_PRODUCT_CANCEL,
});

const cloneProduct = (product: DTO.Product): ProductsAction => ({
  type: ProductsConstants.CLONE_PRODUCT,
  payload: { product },
});

const cancelCloneProduct = (): ProductsAction => ({
  type: ProductsConstants.CLONE_PRODUCT_CANCEL,
});

const updateProduct = (
  productId: string,
  product: DTO.UpdateProductRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.UPDATE_PRODUCT_REQUEST });

    const { status, payload } = await ProductService.updateProduct(
      productId,
      product
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.UPDATE_PRODUCT_SUCCESS,
      payload: {
        selectedProductName: product.name || productId,
        changes: product,
      },
    });

    dispatch(AlertActions.success('NewProductForm.submit.edit.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.UPDATE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const saveProduct = (
  product: DTO.CreateProductRequest
): ProductsThunkAction<string | null> => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.CREATE_PRODUCT_REQUEST });

    const { status, payload } = await ProductService.createProducts(product);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_SUCCESS,
      payload: { selectedProductName: product.name },
    });

    return product.name;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });

    return null;
  }
};

const cloneProductRequest = (
  product: DTO.CloneProductRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.CLONE_PRODUCT_REQUEST });

    const { status, payload } = await ProductService.cloneProduct(product);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CLONE_PRODUCT_SUCCESS,
      payload: { newProductName: product.name },
    });

    dispatch(AlertActions.success('NewProductForm.submit.close.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CLONE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const setFavoriteProduct = (
  productId: string,
  favorite: boolean
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_REQUEST,
      payload: { productId, favorite },
    });

    const { status, payload } = await ProductService.setFavoriteProduct(
      productId,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_SUCCESS,
      payload: { productId },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_FAILURE,
      payload: {
        productId,
        error: msg,
      },
    });
  }
};

const uploadEngine = (): ProductsAction => ({
  type: ProductsConstants.UPLOAD_ENGINE,
});

const cancelUploadEngine = (): ProductsAction => ({
  type: ProductsConstants.UPLOAD_ENGINE_CANCEL,
});

const editProduct = (
  product: DTO.Product,
  summary?: boolean
): ProductsAction => ({
  type: ProductsConstants.EDIT_PRODUCT,
  payload: { product, summary },
});

const cancelEditProduct = (): ProductsAction => ({
  type: ProductsConstants.EDIT_PRODUCT_CANCEL,
});

const editCoverImage = (product: DTO.Product): ProductsAction => ({
  type: ProductsConstants.EDIT_COVER_IMAGE,
  payload: { product },
});

const resetCoverUpload = (): ProductsAction => ({
  type: ProductsConstants.COVER_IMAGE_UPLOAD_RESET,
});

const cancelEditCoverImage = (): ProductsAction => ({
  type: ProductsConstants.EDIT_COVER_IMAGE_CANCEL,
});

const cleanSelectedProduct = (): ProductsAction => ({
  type: ProductsConstants.CLEAN_SELETED_PRODUCT,
});

const getProductCoverImage = (
  imagePath: string
): ProductsThunkAction<string> => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL,
      payload: { imagePath },
    });

    const { status, payload } = await ProductService.getCoverImage(imagePath);

    if (status !== 200) {
      throw new ApiError(payload);
    }

    const imageUrl = URL.createObjectURL(payload.blob);

    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL_SUCCESS,
      payload: {
        imagePath,
        imageUrl,
        imageData: payload.blob,
      },
    });

    return imageUrl;
  } catch (error) {
    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL_FAILURE,
      payload: {
        imagePath,
        error: error.toString(),
      },
    });

    return '';
  }
};

const getProductDetail = (productName: string): ProductsThunkAction => async (
  dispatch,
  getState
) => {
  try {
    const {
      products: { isLoadingProductDetail, selectedProductName },
    } = getState();

    if (isLoadingProductDetail && selectedProductName === productName) {
      return;
    }
    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_REQUEST,
      payload: { productName },
    });

    const { status, payload } = await ProductService.getProductDetails(
      productName
    );

    if (status !== 200 || payload.status !== 'Success') {
      if (
        payload.errorCode === ProductsConstants.ERROR_CODE_INVALID_PRODUCT_NAME
      ) {
        // Try to get shared product details
        // TODO: Need to check to should keep this or require /shared prefix on url path
        const {
          status: statusForShared,
          payload: payloadForShared,
        } = await ProductService.getSharedProductDetails(productName);

        if (statusForShared !== 200 || payloadForShared.status !== 'Success') {
          throw new ApiError(payload);
        }
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_SUCCESS,
      payload: {
        productName,
        product: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_FAILURE,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const changeProductStatus = (
  productId: string,
  productStatus: string,
  fromStatus: string
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_REQUEST,
      payload: { productId, status: productStatus },
    });

    const { status, payload } = await ProductService.updateProduct(productId, {
      status: productStatus,
    });

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_SUCCESS,
      payload: {
        productId,
        status: productStatus,
        fromStatus,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_FAILURE,
      payload: {
        productId,
        error: msg,
      },
    });
  }
};

const uploadDocumentModal = (
  documentType: DTO.ProductDocType
): ProductsAction => ({
  type: ProductsConstants.UPLOAD_DOCUMENT,
  payload: { documentType },
});

const cancelUploadDocumentModal = (): ProductsAction => ({
  type: ProductsConstants.UPLOAD_DOCUMENT_CANCEL,
});

const getProductRecentChanges = (
  productId: string,
  data: DTO.GetRecentChangeRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.GET_RECENT_CHANGES_REQUEST });

    const { payload, status } = await ProductService.getRecentChanges(
      productId,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.GET_RECENT_CHANGES_SUCCESS,
      payload: {
        nextPageToken: payload.next,
        data: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_RECENT_CHANGES_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearRecentChanges = (): ProductsAction => ({
  type: ProductsConstants.CLEAR_RECENT_CHANGES,
});

const downloadProduct = (data: {
  name: string;
  folderPath: string;
  isShared: boolean;
  selectedMetadata: DTO.DownloadFolderMetadata[];
}) =>
  createAsyncThunk(
    async (dispatch, getState) => {
      const {
        auth: { userAuth },
      } = getState();

      if (!userAuth) {
        return false;
      }

      const { refresh_token } = userAuth;

      dispatch({
        type: ProductsConstants.DOWNLOAD_PRODUCT_REQUEST,
        payload: data,
      });

      const { payload } = await FileManagerService.downloadFolder(
        data.folderPath,
        {
          name: data.name,
          refreshToken: refresh_token,
          isShared: data.isShared,
          folders: data.selectedMetadata,
        }
      );

      dispatch({
        type: ProductsConstants.DOWNLOAD_PRODUCT_SUCCESS,
        payload: {
          ...data,
          jobId: payload.data,
        },
      });

      dispatch({
        type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
      });

      return true;
    },
    (_, msg, dispatch) => {
      dispatch({
        type: ProductsConstants.DOWNLOAD_PRODUCT_FAILURE,
        payload: {
          ...data,
          error: msg,
        },
      });

      return false;
    }
  );

const uploadProductZip = (file: File) =>
  createAsyncThunk(
    async (dispatch, getState) => {
      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_START,
      });

      const { status, payload } = await ProductService.uploadProductZip(
        file,
        uploadProgress => {
          if (uploadProgress === 100) {
            return;
          }

          // 5% for server processing time
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_PROGRESS,
            payload: {
              uploadProgress: Math.max(5, uploadProgress - 5),
            },
          });
        },
        xhrRef => {
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_XHR_REF,
            payload: { xhrRef },
          });
        }
      );

      if (status !== 200 || payload.status !== 'Success') {
        const {
          language: { intl },
        } = getState();

        dispatch({
          type: ProductsConstants.UPLOAD_PRODUCT_FAILURE,
          payload: { error: intl.formatMessage({ id: 'INVALID_PRODUCT_ZIP' }) },
        });

        return;
      }

      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_SUCCESS,
        payload: {
          result: payload.data,
        },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_FAILURE,
        payload: { error: msg },
      });
    }
  );

const createProductByZip = (data: DTO.CreateProductByZipRequest) => {
  const productName = data.Product.Name.trim();

  return createAsyncThunk(
    async (dispatch, getState) => {
      const { userAuth } = getState().auth;

      dispatch({
        type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_REQUEST,
        payload: { productName },
      });

      await ProductService.createProductByZipFile({
        ...data,
        RefershToken: userAuth?.refresh_token || '',
      });

      dispatch({
        type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_SUCCESS,
        payload: { productName },
      });

      dispatch({
        type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
      });

      return productName;
    },
    (_, msg, dispatch) => {
      dispatch({
        type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_FAILURE,
        payload: {
          error: msg,
          productName,
        },
      });

      return null;
    }
  );
};

const resetUploadProduct = (): AppAction => ({
  type: ProductsConstants.UPLOAD_PRODUCT_RESET,
});

export const ProductsActions = {
  getProducts,
  newProduct,
  cancelNewProduct,
  cloneProduct,
  cancelCloneProduct,
  saveProduct,
  uploadEngine,
  cancelUploadEngine,
  setFavoriteProduct,
  editProduct,
  cancelEditProduct,
  editCoverImage,
  resetCoverUpload,
  cancelEditCoverImage,
  updateProduct,
  uploadCoverImage,
  getProductCoverImage,
  getProductDetail,
  changeProductStatus,
  cleanSelectedProduct,
  uploadDocumentModal,
  cancelUploadDocumentModal,
  cloneProductRequest,
  getProductRecentChanges,
  clearRecentChanges,
  getAnalysisReport,
  downloadProduct,
  uploadProductZip,
  resetUploadProduct,
  createProductByZip,
};
