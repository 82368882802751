import makeRequest from '../helpers/make-request';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;

const login = (username: string, password: string) => {
  const url = `${USERSTORE_DOMAIN}/users/auth/login/`;
  const body = { username, password };

  return makeRequest<{
    id_token: string;
    refresh_token: string;
  }>('POST', url, body);
};

const passwordReset = (email: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset`;
  const body = { email };

  return makeRequest('POST', url, body);
};

const passwordResetCodeResend = (email: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset/confirm`;
  const body = { email };

  return makeRequest('POST', url, body);
};

const passwordResetConfirm = (
  email: string,
  code: string,
  password: string
) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset/confirm`;
  const body = {
    email,
    code,
    new_password: password,
  };

  return makeRequest('PUT', url, body);
};

const createAnonymousUser = (anonymousUser: DTO.AnonymousUser) => {
  const url = `${USERSTORE_DOMAIN}/acl/api/anonusers`;
  return makeRequest<{
    user_id: string;
  }>('POST', url, anonymousUser);
};

const generateAnonymousLink = (anonymousLink: DTO.AnonymousLink) => {
  const url = `${USERSTORE_DOMAIN}/acl/api/links`;
  return makeRequest<{
    link_id: string;
    link_url: string;
  }>('POST', url, anonymousLink);
};

export const UserService = {
  login,
  passwordReset,
  passwordResetCodeResend,
  passwordResetConfirm,
  createAnonymousUser,
  generateAnonymousLink,
};
