import { UserConstants } from '../constants';
import { UserAction } from '../actions/types';

export const initialState: STATES.AnonymousUserState = {
  isLoading: false,
  user_id: '',
  link_id: '',
  link_url: '',
};

export const anonymousUser = (
  state = initialState,
  action: UserAction
): STATES.AnonymousUserState => {
  switch (action.type) {
    case UserConstants.CREATE_ANONYMOUS_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case UserConstants.CREATE_ANONYMOUS_USER_SUCCESS: {
      const { userId } = action.payload;
      return {
        ...state,
        isLoading: false,
        user_id: userId,
      };
    }
    case UserConstants.CREATE_ANONYMOUS_FAILURE: {
      return {
        ...initialState,
      };
    }
    case UserConstants.CREATE_ANONYMOUS_LINK_SUCCESS: {
      const { linkId, linkUrl } = action.payload;
      return {
        ...state,
        isLoading: false,
        link_id: linkId,
        link_url: linkUrl,
      };
    }
    default:
      return state;
  }
};
