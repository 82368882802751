export const ERROR_CODE_INVALID_PRODUCT_NAME = 'INVALID_PRODUCT_NAME';

export const PRODUCT_DOWNLOAD_OPTIONS: DTO.DataTreeProps[] = [
  {
    value: 'AllFolders',
    title: 'DownloadProduct.download.modal.options.allFolders',
    key: 'AllFolders',
    children: [
      {
        value: 'Services',
        title: 'DownloadProduct.download.modal.options.engines',
        key: 'Services',
        children: [],
      },
      {
        value: 'ProductDetails',
        title: 'DownloadProduct.download.modal.options.brochure',
        key: 'ProductDetails',
        children: [],
      },
      {
        value: 'ProductDocumentation',
        title: 'DownloadProduct.download.modal.options.domentation',
        key: 'ProductDocumentation',
        children: [],
      },
      {
        value: 'AnalysisReport',
        title: 'DownloadProduct.download.modal.options.anaylysis',
        key: 'AnalysisReport',
        children: [],
      },
      {
        value: 'Images',
        title: 'DownloadProduct.download.modal.options.images',
        key: 'Images',
        children: [],
      },
      {
        value: 'TestResultFiles',
        title: 'DownloadProduct.download.modal.options.testResult',
        key: 'TestResultFiles',
        children: [],
      },
    ],
  },
];

export const PRODUCT_CATEGORIES = {
  HealthAccident: {
    icon: 'ProductCatHealthAccident',
    title: 'Health & Accident',
  },
  Life: {
    icon: 'ProductCatLife',
    title: 'Life',
  },
  HouseHold: {
    icon: 'ProductCatHouseHold',
    title: 'Household',
  },
  Travel: {
    icon: 'ProductCatTravel',
    title: 'Travel',
  },
  CarsBoats: {
    icon: 'ProductCatCarsBoats',
    title: 'Cars & Boats',
  },
  Invest: {
    icon: 'ProductCatInvest',
    title: 'Invest',
  },
  Savings: {
    icon: 'ProductCatSaving',
    title: 'Savings',
  },
  Retirement: {
    icon: 'ProductCatRetirement',
    title: 'Retirement',
  },
  // Seasonalife v01
  Annuity: {
    icon: 'ProductCatSLAnnuity',
    title: 'Annuity',
  },
  'Investment Linked': {
    icon: 'ProductCatSLInvestmentLinked',
    title: 'Investment Linked',
  },
  'Lifelong Participation': {
    icon: 'ProductCatSLLifelongParticipation',
    title: 'Lifelong Participation',
  },
  Medical: {
    icon: 'ProductCatSLMedical',
    title: 'Medical',
  },
  Other: {
    icon: 'ProductCatSLOther',
    title: 'Other',
  },
  'Critical Illness': {
    icon: 'ProductCatSLCriticalIllness',
    title: 'Critical Illness',
  },
  Term: {
    icon: 'ProductCatSLTerm',
    title: 'Term',
  },
  'Universal Life': {
    icon: 'ProductCatSLUniversalLife',
    title: 'Universal Life',
  },
  'VHIS + Medical': {
    icon: 'ProductCatSLVHISMedical',
    title: 'VHIS + Medical',
  },
  VHIS: {
    icon: 'ProductCatSLVHIS',
    title: 'VHIS',
  },
};

export const PRODUCT_FILE_TYPES = {
  EXCEL: {
    icon: 'ExcelFile',
  },
  PDF: {
    icon: 'PdfFile',
  },
  WORD: {
    icon: 'WordFile',
  },
  POWERPOINT: {
    icon: 'PowerpointFile',
  },
  IMAGE: {
    icon: 'file-image',
  },
  HTML: {
    icon: 'File',
  },
  HTM: {
    icon: 'File',
  },
};

export const PRODUCT_STATUS = {
  Production: {
    icon: 'ProductStatusProduction',
    iconOutlined: 'ProductStatusProductionOutlined',
    title: 'Production',
    bgcolor: 'purple',
    char: 'P',
    level: '2',
    alias: false,
  },
  Design: {
    icon: 'ProductStatusDesign',
    iconOutlined: 'ProductStatusDesignOutlined',
    title: 'Design',
    bgcolor: 'aqua',
    char: 'D',
    level: '1',
    alias: false,
  },
  Archive: {
    icon: 'ProductStatusArchive',
    iconOutlined: 'ProductStatusArchiveOutlined',
    title: 'Archive',
    bgcolor: 'dark',
    char: 'A',
    level: '3',
    alias: false,
  },
  'In Production': {
    icon: 'ProductStatusProduction',
    iconOutlined: 'ProductStatusProductionOutlined',
    title: 'Production',
    bgcolor: 'purple',
    char: 'P',
    level: '2',
    alias: true,
  },
  'In Design': {
    icon: 'ProductStatusDesign',
    iconOutlined: 'ProductStatusDesignOutlined',
    title: 'Design',
    bgcolor: 'aqua',
    char: 'D',
    level: '1',
    alias: true,
  },
  'In Archive': {
    icon: 'ProductStatusArchive',
    iconOutlined: 'ProductStatusArchiveOutlined',
    title: 'Archive',
    bgcolor: 'dark',
    char: 'A',
    level: '3',
    alias: true,
  },
};

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const NEW_PRODUCT = 'NEW_PRODUCT';
export const NEW_PRODUCT_CANCEL = 'NEW_PRODUCT_CANCEL';
export const CLONE_PRODUCT = 'CLONE_PRODUCT';
export const CLONE_PRODUCT_CANCEL = 'CLONE_PRODUCT_CANCEL';
export const CLONE_PRODUCT_REQUEST = 'CLONE_PRODUCT_REQUEST';
export const CLONE_PRODUCT_SUCCESS = 'CLONE_PRODUCT_SUCCESS';
export const CLONE_PRODUCT_FAILURE = 'CLONE_PRODUCT_FAILURE';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const UPLOAD_ENGINE = 'UPLOAD_ENGINE';
export const UPLOAD_ENGINE_CANCEL = 'UPLOAD_ENGINE_CANCEL';
export const EDIT_COVER_IMAGE = 'EDIT_COVER_IMAGE';
export const EDIT_COVER_IMAGE_CANCEL = 'EDIT_COVER_IMAGE_CANCEL';
export const COVER_IMAGE_UPLOAD_RESET = 'COVER_IMAGE_UPLOAD_RESET';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_CANCEL = 'EDIT_PRODUCT_CANCEL';
export const CLEAN_SELETED_PRODUCT = 'CLEAN_SELETED_PRODUCT';
export const COVER_IMAGE_UPLOAD_START = 'COVER_IMAGE_UPLOAD_START';
export const COVER_IMAGE_UPLOAD_PROGRESS = 'COVER_IMAGE_UPLOAD_PROGRESS';
export const COVER_IMAGE_SUCCESS = 'COVER_IMAGE_SUCCESS';
export const COVER_IMAGE_FAILURE = 'COVER_IMAGE_FAILURE';
export const COVER_IMAGE_GET_URL = 'COVER_IMAGE_GET_URL';
export const COVER_IMAGE_GET_URL_SUCCESS = 'COVER_IMAGE_GET_URL_SUCCESS';
export const COVER_IMAGE_GET_URL_FAILURE = 'COVER_IMAGE_GET_URL_FAILURE';
export const FAVORITE_PRODUCT_REQUEST = 'FAVORITE_PRODUCT_REQUEST';
export const FAVORITE_PRODUCT_SUCCESS = 'FAVORITE_PRODUCT_SUCCESS';
export const FAVORITE_PRODUCT_FAILURE = 'FAVORITE_PRODUCT_FAILURE';
export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';
export const CHANGE_PRODUCT_STATUS_REQUEST = 'CHANGE_PRODUCT_STATUS_REQUEST';
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS';
export const CHANGE_PRODUCT_STATUS_FAILURE = 'CHANGE_PRODUCT_STATUS_FAILURE';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_CANCEL = 'UPLOAD_DOCUMENT_CANCEL';
export const SHARE_PRODUCT_REQUEST = 'SHARE_PRODUCT_REQUEST';
export const SHARE_PRODUCT_SUCCESS = 'SHARE_PRODUCT_SUCCESS';
export const SHARE_PRODUCT_FAILURE = 'SHARE_PRODUCT_FAILURE';
export const GET_RECENT_CHANGES_REQUEST = 'GET_RECENT_CHANGES_REQUEST';
export const GET_RECENT_CHANGES_SUCCESS = 'GET_RECENT_CHANGES_SUCCESS';
export const GET_RECENT_CHANGES_FAILURE = 'GET_RECENT_CHANGES_FAILURE';
export const CLEAR_RECENT_CHANGES = 'CLEAR_RECENT_CHANGES';
export const GET_PRODUCT_SHARE_STATE_REQUEST =
  'GET_PRODUCT_SHARE_STATE_REQUEST';
export const GET_PRODUCT_SHARE_STATE_SUCCESS =
  'GET_PRODUCT_SHARE_STATE_SUCCESS';
export const GET_PRODUCT_SHARE_STATE_FAILURE =
  'GET_PRODUCT_SHARE_STATE_FAILURE';

export const DOWNLOAD_PRODUCT_REQUEST = 'DOWNLOAD_PRODUCT_REQUEST';
export const DOWNLOAD_PRODUCT_FAILURE = 'DOWNLOAD_PRODUCT_FAILURE';
export const DOWNLOAD_PRODUCT_SUCCESS = 'DOWNLOAD_PRODUCT_SUCCESS';

export const UPLOAD_PRODUCT_START = 'UPLOAD_PRODUCT_START';
export const UPLOAD_PRODUCT_PROGRESS = 'UPLOAD_PRODUCT_PROGRESS';
export const UPLOAD_PRODUCT_XHR_REF = 'UPLOAD_PRODUCT_XHR_REF';
export const UPLOAD_PRODUCT_SUCCESS = 'UPLOAD_PRODUCT_SUCCESS';
export const UPLOAD_PRODUCT_FAILURE = 'UPLOAD_PRODUCT_FAILURE';
export const UPLOAD_PRODUCT_RESET = 'UPLOAD_PRODUCT_RESET';

export const CREATE_PRODUCT_BY_ZIP_FILE_REQUEST =
  'CREATE_PRODUCT_BY_ZIP_FILE_REQUEST';
export const CREATE_PRODUCT_BY_ZIP_FILE_SUCCESS =
  'CREATE_PRODUCT_BY_ZIP_FILE_SUCCESS';
export const CREATE_PRODUCT_BY_ZIP_FILE_FAILURE =
  'CREATE_PRODUCT_BY_ZIP_FILE_FAILURE';
