import React, { NamedExoticComponent, memo } from 'react';
import { Row, Col, Icon, Progress } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colorHexToRgba } from '../../../helpers';

interface ProgressBarProps {
  className?: string;
  progress: number;
  icon: string;
  title: string;
  iconColor: string;
  textColor: string;
  progressColor: string;
}

const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    background-color: ${({ theme }) =>
      colorHexToRgba(theme.vars.secondaryColor, 0.1)};
  }
`;

const StyledProgressContainer = styled(Row)<{
  textColor: string;
  iconColor: string;
  progressColor: string;
}>`
  margin-top: 1rem;

  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 0 !important;
    height: 16px !important;
  }

  span.label {
    color: ${({ textColor }) => textColor};
  }

  .ant-progress-bg {
    background-color: ${({ progressColor }) => progressColor} !important;
  }

  .anticon {
    color: ${({ iconColor }) => iconColor} !important;
  }

  .ant-progress-inner {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.1)};
  }
`;

const StyledProgressIcon = styled(Icon)`
  float: right;
  margin-top: 2px;
  margin-left: 10px;
  font-size: 18px;
`;

const ProgressContainer = styled.div`
  flex: 1;
`;

const ProgressBar: NamedExoticComponent<ProgressBarProps> = memo(
  ({
    progress,
    icon,
    title,
    textColor,
    progressColor,
    className,
    iconColor,
  }) => {
    return (
      <StyledProgressContainer
        className={className}
        gutter={4}
        textColor={textColor}
        progressColor={progressColor}
        iconColor={iconColor}
      >
        <Col span={8}>
          <span className="label">
            <FormattedMessage id={title} />
          </span>
        </Col>
        <Col span={16}>
          <Row type="flex" align="middle">
            <ProgressContainer>
              <StyledProgress
                strokeLinecap="square"
                showInfo={false}
                percent={progress}
              />
            </ProgressContainer>

            <StyledProgressIcon type={icon} />
          </Row>
        </Col>
      </StyledProgressContainer>
    );
  }
);

export { ProgressBar };
