import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  loggedIn: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  loggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (loggedIn) {
        if (render) return render(props);

        if (Component) return <Component {...props} />;

        return <Redirect to="/" />;
      }

      return (
        <Redirect
          to={`/login/?return_url=${encodeURIComponent(
            window.location.href.replace(window.location.origin, '')
          )}`}
        />
      );
    }}
  />
);

export { PrivateRoute };
