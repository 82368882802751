import { UserConstants } from '../constants';
import { UserAction, UserThunkAction } from './types';
import { UserService } from '../services';
import { ApiError, getValidRoleAndGroup } from '../helpers';
import { AlertActions } from './alert.actions';
import { isAdmin, isSuperuser, verifyToken } from '../helpers/user-auth';

const resetPassword = (
  email: string,
  resend?: boolean
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.PASSWORD_RESET_REQUEST,
      payload: { email },
    });

    const request = resend
      ? UserService.passwordResetCodeResend(email)
      : UserService.passwordReset(email);

    const { payload } = await request;

    if (!payload || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({ type: UserConstants.PASSWORD_RESET_REQUEST_SUCCESS });

    if (resend) {
      dispatch(AlertActions.success('UserManagerUserForm.verify.email.send'));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.PASSWORD_RESET_REQUEST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetPasswordConfirm = (
  email: string,
  code: string,
  password: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.PASSWORD_RESET_CONFIRM,
    });

    const { payload } = await UserService.passwordResetConfirm(
      email,
      code,
      password
    );

    if (!payload || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({ type: UserConstants.PASSWORD_RESET_CONFIRM_SUCCESS });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.PASSWORD_RESET_CONFIRM_FAILURE,
      payload: { error: msg },
    });
  }
};

const login = (
  username: string,
  password: string,
  remember: boolean
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.LOGIN_REQUEST, payload: { remember } });

    const { payload } = await UserService.login(username, password);

    if (payload.status !== 'OK') {
      switch (payload.error_code) {
        case 'ACCOUNT_LOCKED':
        case 'PASSWORD_RESET_REQUIRED': {
          dispatch({ type: UserConstants.LOGIN_CHANGE_PASSWORD_REQUIRED });
          window.location.href = '/forgot/password';
          return;
        }
        case 'PASSWORD_EXPIRED': {
          dispatch({
            type: UserConstants.LOGIN_FAILURE,
            payload: { reason: 'PasswordExpired' },
          });
          return;
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    const decodedJwt = await verifyToken(payload);

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: payload.id_token,
            refresh_token: payload.refresh_token,
            username,
          },
          userInfo: {
            displayName:
              decodedJwt.display_name ||
              `${decodedJwt.first_name} ${decodedJwt.last_name}`,
            userEmailID: username,
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt),
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createAnonymousUser = (
  anonymousUser: DTO.AnonymousUser
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.createAnonymousUser(anonymousUser);

    if (!payload || payload.status !== 'OK') {
      if (payload.error_code === 'INVALID_INPUT') {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_USER_SUCCESS,
      payload: {
        userId: payload.user_id,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const generateAnonymousLink = (
  anonymousLink: DTO.AnonymousLink
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.generateAnonymousLink(anonymousLink);

    if (!payload || payload.status !== 'OK') {
      switch (payload.error_code) {
        case 'PERMISSION_DENIED': {
          throw new ApiError({
            error_code: 'PERMISSION_DENIED_ANONYMOUSLINK',
          });
        }
        case 'USER_NOT_FOUND': {
          throw new ApiError({
            error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
          });
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_LINK_SUCCESS,
      payload: {
        linkUrl: payload.link_url,
        linkId: payload.link_id,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const logout = (): UserAction => {
  return { type: UserConstants.LOGOUT };
};

const loggedOut = (): UserAction => {
  return { type: UserConstants.LOGGED_OUT };
};

const resetPasswordClean = (): UserAction => {
  return { type: UserConstants.PASSWORD_RESET_CLEAN };
};

const loginClean = (): UserAction => {
  return { type: UserConstants.LOGIN_CLEAN };
};

const setIdToken = (id_token: string): UserAction => ({
  type: UserConstants.SET_ID_TOKEN,
  payload: {
    id_token,
  },
});

const setAnonymousIdToken = (token: string): UserAction => ({
  type: UserConstants.SET_ANONYMOUS_ID_TOKEN,
  payload: {
    id_token: token,
  },
});

export const UserActions = {
  login,
  resetPassword,
  resetPasswordConfirm,
  logout,
  loggedOut,
  setIdToken,
  resetPasswordClean,
  loginClean,
  createAnonymousUser,
  generateAnonymousLink,
  setAnonymousIdToken,
};
