import React, { FC } from 'react';
import queryString from 'query-string';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface AnonymousRouteProps extends RouteProps {
  loggedIn: boolean;
}

const AnonymousRoute: FC<AnonymousRouteProps> = ({
  component,
  render,
  loggedIn,
  ...rest
}) => {
  let rediectAfterLoggedIn = '/';

  if (rest.location && rest.location.search) {
    const qss = queryString.parse(rest.location.search);

    if (qss.return_url && typeof qss.return_url === 'string') {
      rediectAfterLoggedIn = qss.return_url;
    }
  }

  if (loggedIn) {
    return (
      <Route {...rest} render={() => <Redirect to={rediectAfterLoggedIn} />} />
    );
  }

  return <Route component={component} render={render} {...rest} />;
};

export { AnonymousRoute };
