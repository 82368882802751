import { isEqual } from 'lodash-es';
import { FileManagerConstants, BackgroundJobsConstants } from '../constants';
import { AppAction } from '../actions/types';
import { arrayRemoveFirst, arrayReplaceFirst } from '../helpers';

const initialRootState: STATES.FileManagerRootState = {
  selectedFolderPathOnFiles: '',
  isLoadingFilesAndFolders: false,
  waitingSelectedFolderDetails: null,
  isLoadingFiles: false,
  isLoadingFileDetails: false,
  selectedFileId: null,
  selectedFolderDetails: null,
  isUpdatingFolder: false,
  selectedFile: null,
  isUpdatingFile: false,
  files: [],
  folders: [],
  filesAndFolders: {
    files: [],
    folders: [],
  },
  totalFiles: 0,
  totalFilesPages: 0,
  filesRequest: {
    page: 1,
    pageSize: 100,
    searchText: '',
    sort: 'desc',
    sortBy: 'updated',
  },
  foldersAndFilesRequest: {
    page: 1,
    pageSize: 100,
    searchText: '',
    sort: 'desc',
    sortBy: 'updated',
  },
  filesRefreshTrigger: 0,
  isLoadingFolders: false,
  sortFilesAndFolder: false,
  sharingFolderPath: '',
  folderShareGroups: null,
  foldersRequest: {
    page: 1,
    pageSize: 100,
    searchText: '',
    sort: 'desc',
    sortBy: 'updated',
  },
  foldersInParent: [],
  totalFolders: 0,
  totalFoldersPages: 0,
  selectedParentFolder: '',
  foldersInAction: {},
  subFoldersInAction: {},
};

export const initialState: STATES.FileManagerState = {
  home: { ...initialRootState },
  shared: { ...initialRootState },
};

export const fileManager = (
  state = initialState,
  action: AppAction
): STATES.FileManagerState => {
  switch (action.type) {
    case FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_REQUEST: {
      const { folderPath, root, foldersAndFilesRequest } = action.payload;
      return {
        ...state,
        [root]: {
          ...state[root],
          selectedFolderPathOnFiles: folderPath,
          isLoadingFilesAndFolders: true,
          totalFolders: 0,
          totalFoldersPages: 0,
          foldersAndFilesRequest,
          foldersInAction: {},
          filesAndFolders: {
            files: [],
            folders: [],
          },
        },
      };
    }
    case FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_SUCCESS: {
      const { root, foldersAndFilesRequest, folders, files } = action.payload;

      let rootState = state[root];

      rootState = {
        ...rootState,
        isLoadingFilesAndFolders: false,
        foldersAndFilesRequest,
        filesAndFolders: {
          files,
          folders,
        },
      };

      return {
        ...state,
        [root]: rootState,
      };
    }
    case FileManagerConstants.GET_FOLDERS_AND_FILES_IN_PARENT_FAILURE: {
      const { folderPath, root, foldersAndFilesRequest } = action.payload;

      const rootState = state[root];

      if (
        rootState.selectedParentFolder !== folderPath ||
        !isEqual(rootState.foldersAndFilesRequest, foldersAndFilesRequest)
      ) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...rootState,
          isLoadingFilesAndFolders: false,
        },
      };
    }
    case FileManagerConstants.GET_FOLDERS_IN_PARENT_REQUEST: {
      const { folderPath, root, foldersRequest } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          selectedParentFolder: folderPath,
          isLoadingFolders: true,
          sortFilesAndFolder: false,
          foldersInParent: [],
          totalFolders: 0,
          totalFoldersPages: 0,
          foldersRequest,
          foldersInAction: {},
        },
      };
    }
    case FileManagerConstants.GET_FOLDERS_IN_PARENT_SUCCESS: {
      const {
        folderPath,
        root,
        foldersRequest,
        folders,
        total,
      } = action.payload;

      let rootState = state[root];

      if (
        rootState.selectedParentFolder !== folderPath ||
        !isEqual(rootState.foldersRequest, foldersRequest)
      ) {
        return state;
      }

      rootState = {
        ...rootState,
        isLoadingFolders: false,
        foldersInParent: folders,
        sortFilesAndFolder: false,
        totalFolders: total,
        totalFoldersPages:
          Math.floor(total / rootState.foldersRequest.pageSize) +
          (total % rootState.foldersRequest.pageSize ? 1 : 0),
      };

      if (rootState.waitingSelectedFolderDetails) {
        const folder = rootState.foldersInParent.find(
          x =>
            rootState.waitingSelectedFolderDetails ===
            `${rootState.selectedParentFolder}${x.name}`
        );

        if (folder) {
          rootState.selectedFolderDetails = folder;
        }

        rootState.waitingSelectedFolderDetails = null;
      }

      return {
        ...state,
        [root]: rootState,
      };
    }
    case FileManagerConstants.GET_FOLDERS_IN_PARENT_FAILURE: {
      const { folderPath, root, foldersRequest } = action.payload;

      const rootState = state[root];

      if (
        rootState.selectedParentFolder !== folderPath ||
        !isEqual(rootState.foldersRequest, foldersRequest)
      ) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...rootState,
          isLoadingFolders: false,
        },
      };
    }
    case FileManagerConstants.ADD_FOLDER: {
      const { root, timestamp } = action.payload;

      const rootState = state[root];

      return {
        ...state,
        [root]: {
          ...state[root],
          foldersInParent: [
            {
              id: timestamp,
              name: timestamp,
              path: rootState.selectedParentFolder,
              metadata: {},
              created_at: new Date().toISOString(),
            },
            ...state[root].foldersInParent,
          ],
          foldersInAction: {
            ...rootState.foldersInAction,
            [timestamp]: 'new',
          },
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_REQUEST: {
      const { folderTimestamp, root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          foldersInAction: {
            ...state[root].foldersInAction,
            [folderTimestamp]: 'saving',
          },
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_SUCCESS: {
      const { folderTimestamp, root, folder } = action.payload;

      const rootState = state[root];

      const {
        [folderTimestamp]: _,
        ...foldersInAction
      } = rootState.foldersInAction;

      const foldersInParent = arrayReplaceFirst(
        rootState.foldersInParent,
        f => f.id === folderTimestamp,
        folder
      );

      return {
        ...state,
        [root]: {
          ...state[root],
          foldersInAction,
          foldersInParent,
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_FAILURE: {
      const { root, folderTimestamp } = action.payload;

      const rootState = state[root];

      const {
        [folderTimestamp]: _,
        ...foldersInAction
      } = rootState.foldersInAction;

      const folders = arrayRemoveFirst(
        rootState.foldersInParent,
        folder => folder.id === folderTimestamp
      );

      return {
        ...state,
        [root]: {
          ...state[root],
          foldersInAction,
          folders,
        },
      };
    }
    case FileManagerConstants.CANCEL_NEW_FOLDER: {
      const { folderName, root } = action.payload;
      const rootState = state[root];
      const { [folderName]: _, ...foldersInAction } = rootState.foldersInAction;
      let foldersInParent = rootState.foldersInParent;

      if (
        rootState.foldersInAction[folderName] &&
        rootState.foldersInAction[folderName] === 'new'
      ) {
        foldersInParent = arrayRemoveFirst(
          foldersInParent,
          folder => folder.name === folderName
        );
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          foldersInParent,
          foldersInAction,
        },
      };
    }
    case FileManagerConstants.SET_WAITING_SELECTED_FOLDER_DETAILS: {
      const { root, folderPath } = action.payload;

      if (state[root].foldersInParent.length > 0) {
        const folder = state[root].foldersInParent.find(
          x => folderPath === `${state[root].selectedParentFolder}${x.name}`
        );

        if (folder) {
          return {
            ...state,
            [root]: {
              ...state[root],
              waitingSelectedFolderDetails: null,
              selectedFolderDetails: folder,
            },
          };
        }
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          waitingSelectedFolderDetails: folderPath,
        },
      };
    }
    case FileManagerConstants.GET_FOLDER_DETAILS_SUCCESS: {
      const { folder, root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          selectedFolderDetails: folder,
        },
      };
    }
    case FileManagerConstants.UPLOAD_FILE_SUCCESS: {
      const { root } = action.payload;

      // force set selectedFolderPathOnFiles to '' will trigger refresh on list

      return {
        ...state,
        [root]: {
          ...state[root],
          filesRefreshTrigger: state[root].filesRefreshTrigger + 1,
          selectedFolderPathOnFiles: '',
        },
      };
    }
    case FileManagerConstants.UPDATE_FOLDER_SUCCESS: {
      const { folderId, metadata, root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          folders: arrayReplaceFirst(
            state[root].folders,
            folder => folder.id === folderId,
            folder => ({ ...folder, metadata })
          ),
          foldersInParent: arrayReplaceFirst(
            state[root].foldersInParent,
            folder => folder.id === folderId,
            folder => ({ ...folder, metadata })
          ),
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_REQUEST: {
      const { folderName, isSubFolder } = action.payload;

      if (isSubFolder) {
        return {
          ...state,
          home: {
            ...state.home,
            subFoldersInAction: {
              ...state.home.subFoldersInAction,
              [folderName]: 'deleting',
            },
          },
        };
      }

      return {
        ...state,
        home: {
          ...state.home,
          foldersInAction: {
            ...state.home.foldersInAction,
            [folderName]: 'deleting',
          },
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_SUCCESS: {
      const { folderName, isSubFolder } = action.payload;

      if (isSubFolder) {
        const folders = arrayRemoveFirst(
          state.home.filesAndFolders.folders,
          folder => folder.name === folderName
        );

        return {
          ...state,
          home: {
            ...state.home,
            folders,
            filesAndFolders: {
              ...state.home.filesAndFolders,
              folders,
            },
          },
        };
      }

      const {
        [folderName]: __,
        ...foldersInAction
      } = state.home.foldersInAction;

      const foldersInParent = arrayRemoveFirst(
        state.home.foldersInParent,
        folder => folder.name === folderName
      );

      return {
        ...state,
        home: {
          ...state.home,
          foldersInParent,
          foldersInAction,
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_FAILURE: {
      const { folderName } = action.payload;

      const {
        [folderName]: _,
        ...foldersInAction
      } = state.home.foldersInAction;

      return {
        ...state,
        home: {
          ...state.home,
          foldersInAction,
        },
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_REQUEST: {
      const { root, fileId } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          selectedFileId: fileId,
          isLoadingFileDetails: true,
          selectedFile: null,
        },
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_SUCCESS: {
      const { root, fileId, file } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          selectedFileId: fileId,
          isLoadingFileDetails: false,
          selectedFile: file,
        },
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_FAILURE: {
      const { root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          isLoadingFileDetails: false,
        },
      };
    }
    case FileManagerConstants.DELETE_FILE_SUCCESS: {
      const { root, fileId } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          filesAndFolders: {
            ...state[root].filesAndFolders,
            files: arrayRemoveFirst(
              state[root].filesAndFolders.files,
              file => file.id === fileId
            ),
          },
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_REQUEST: {
      const { root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          isUpdatingFile: true,
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_SUCCESS: {
      const { root } = action.payload;

      // force set selectedFolderPathOnFiles to '' will trigger refresh on list

      return {
        ...state,
        [root]: {
          ...state[root],
          filesRefreshTrigger: state[root].filesRefreshTrigger + 1,
          isUpdatingFile: false,
          selectedFolderPathOnFiles: '',
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_FAILURE: {
      const { root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          isUpdatingFile: false,
        },
      };
    }

    case FileManagerConstants.FOLDER_SHARE_STATE_REQUEST: {
      const { root, folderPath } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          sharingFolderPath: folderPath,
          folderShareGroups: null,
        },
      };
    }
    case FileManagerConstants.FOLDER_SHARE_STATE_SUCCESS: {
      const { root, folderPath, groups } = action.payload;

      if (folderPath !== state[root].sharingFolderPath) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          folderShareGroups: groups.map(group => ({
            ...group,
            isSharing: false,
          })),
        },
      };
    }
    case FileManagerConstants.FOLDER_SHARE_STATE_FAILURE: {
      const { root, folderPath } = action.payload;

      if (folderPath !== state[root].sharingFolderPath) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          folderShareGroups: [],
        },
      };
    }

    case FileManagerConstants.UPDATE_FOLDER_SHARE_REQUEST: {
      const { root, sharingFolderPath, groupName } = action.payload;

      if (sharingFolderPath !== state[root].sharingFolderPath) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          folderShareGroups: arrayReplaceFirst(
            state[root].folderShareGroups || [],
            group => group.groupName === groupName,
            group => ({ ...group, isSharing: true })
          ),
        },
      };
    }
    case FileManagerConstants.UPDATE_FOLDER_SHARE_SUCCESS: {
      const { root, sharingFolderPath, groupName, shared } = action.payload;

      if (sharingFolderPath !== state[root].sharingFolderPath) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          folderShareGroups: arrayReplaceFirst(
            state[root].folderShareGroups || [],
            group => group.groupName === groupName,
            group => ({
              ...group,
              isSharing: false,
              isShared: shared,
            })
          ),
        },
      };
    }
    case FileManagerConstants.UPDATE_FOLDER_SHARE_FAILURE: {
      const { root, sharingFolderPath, groupName } = action.payload;

      if (sharingFolderPath !== state[root].sharingFolderPath) {
        return state;
      }

      return {
        ...state,
        [root]: {
          ...state[root],
          folderShareGroups: arrayReplaceFirst(
            state[root].folderShareGroups || [],
            group => group.groupName === groupName,
            group => ({
              ...group,
              isSharing: false,
            })
          ),
        },
      };
    }
    case FileManagerConstants.FOLDER_SHARE_STATE_RESET: {
      const { root } = action.payload;

      return {
        ...state,
        [root]: {
          ...state[root],
          sharingFolderPath: '',
          folderShareGroups: null,
        },
      };
    }

    case BackgroundJobsConstants.GET_BGJOBS_SUCCESS: {
      const { reloadProductFolderList } = action.payload;

      if (!reloadProductFolderList) {
        return state;
      }

      return {
        ...state,
        home: {
          ...state.home,
          selectedFolderPathOnFiles: '',
          selectedParentFolder: '',
        },
        shared: {
          ...state.shared,
          selectedFolderPathOnFiles: '',
          selectedParentFolder: '',
        },
      };
    }

    default:
      return state;
  }
};
