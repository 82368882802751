import { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

/**
 * Return current params using on file manager
 * root: My files/folders or Shared files/folders
 * folderPath: current parent folder (folder contains left side folders)
 * selectedFolder: the folder contains folders & files on right side.
 * fileId: the selected file Id (use while editting file)
 * @param onlyForType
 */
const useFileManagerParams = (onlyForType?: DTO.FileManagerRootType) => {
  const location = useLocation();
  const { root: rootParams } = useParams<{ root: string }>();

  let root: DTO.FileManagerRootType = 'home';
  let fileId = '';
  let isEditingFolder = false;
  let folderPath = '';
  let selectedFolder = '';

  if (['home', 'shared'].includes(rootParams.toLowerCase())) {
    root = rootParams.toLowerCase() as DTO.FileManagerRootType;
  }

  const remainingPath = location.pathname.replace(
    `/admin/explorer/${root}`,
    ''
  );
  const splittedPaths = (remainingPath || '/').split('/');

  if (splittedPaths[splittedPaths.length - 1].startsWith('file:')) {
    fileId = splittedPaths[splittedPaths.length - 1].replace('file:', '');
    splittedPaths.splice(splittedPaths.length - 1, 1);
  }

  if (splittedPaths[splittedPaths.length - 1].startsWith('folder:edit')) {
    isEditingFolder = true;
    splittedPaths.splice(splittedPaths.length - 1, 1);
  }

  if (splittedPaths[1] !== '') {
    selectedFolder = `${splittedPaths.join('/')}`;
    folderPath = selectedFolder.substring(
      0,
      selectedFolder.lastIndexOf('/') + 1
    );
  } else {
    selectedFolder = '';
    folderPath = `${splittedPaths.join('/')}`;
  }

  const caches = useRef<
    {
      [key in DTO.FileManagerRootType]?: {
        folderPath: string;
        fileId: string;
        selectedFolder: string;
        isEditingFolder: boolean;
      };
    }
  >({
    [root]: {
      folderPath,
      fileId,
      selectedFolder,
      isEditingFolder,
    },
  });

  const fileManagerParams = useMemo<{
    root: DTO.FileManagerRootType;
    folderPath: string;
    fileId: string;
    selectedFolder: string;
    isEditingFolder: boolean;
  }>(() => {
    if (!onlyForType || root === onlyForType) {
      caches.current[root] = {
        folderPath,
        fileId,
        selectedFolder,
        isEditingFolder,
      };

      return {
        root,
        folderPath,
        fileId,
        selectedFolder,
        isEditingFolder,
      };
    }

    return {
      root: onlyForType,
      ...(caches.current[onlyForType] || {
        folderPath: '/',
        fileId: '',
        selectedFolder: '',
        isEditingFolder: false,
      }),
    };
  }, [folderPath, fileId, root, selectedFolder, onlyForType, isEditingFolder]);

  return fileManagerParams;
};

export { useFileManagerParams };
