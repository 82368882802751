import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getProducts = (
  data: DTO.GetKanbanRoadMapProductsRequest,
  startDate?: string,
  endDate?: string
) => {
  const url =
    `${EXCELENGINE_DOMAIN}/api/v1/productroadmap/getproducts/` +
    `${startDate ? `/${startDate}` : ''}` +
    `${endDate ? `/${endDate}` : ''}`;

  const search: { field: string; value: string }[] = [];

  if (data.searchText) {
    search.push({ field: 'name', value: data?.searchText });
  }

  if (data.filterCategory) {
    search.push({ field: 'category', value: data?.filterCategory });
  }

  return makeRequest<{ data: DTO.Product[] }>('POST', url, {
    search,
    sort: data?.sortBy
      ? `${data?.sort === 'desc' ? '-' : ''}${data?.sortBy}`
      : '',
  });
};

export const ProductRoadMapServices = {
  getProducts,
};
