import moment from 'moment';
import { IntlShape } from 'react-intl';

const formatToLongDateTime = (inp: moment.MomentInput) =>
  moment(inp)
    .utc()
    .format('YYYY-MM-DD HH:mm UTC');

const formatToShortDateTime = (inp?: moment.MomentInput, format?: string) =>
  moment(inp, format)
    .utc()
    .format('YYYY-MM-DD');

const customFormatToDateTime = (
  inp?: moment.MomentInput,
  outputFormat?: string,
  format?: string
) =>
  moment(inp, format)
    .utc()
    .format(outputFormat);

const validateMasterReferenceEffectiveDate = (
  tmpActivePeriod: DTO.ActivePeriod,
  tmpWarnings: string[],
  intl: IntlShape
) => {
  if (
    tmpActivePeriod?.masterReferenceDetail &&
    tmpActivePeriod?.dateTypeOption &&
    tmpActivePeriod?.isMasterReferenceApplied
  ) {
    let smallestMasterDate: Date | undefined;
    let highestMasterDate: Date | undefined;

    tmpActivePeriod?.masterReferenceDetail?.BlockReference.forEach(
      masterList => {
        if (
          (smallestMasterDate &&
            masterList.EffectiveFromDate &&
            moment(smallestMasterDate).isAfter(masterList.EffectiveFromDate)) ||
          !smallestMasterDate
        ) {
          smallestMasterDate = masterList.EffectiveFromDate;
        }
        if (
          (highestMasterDate &&
            masterList.EffectiveToDate &&
            moment(highestMasterDate).isBefore(masterList.EffectiveToDate)) ||
          !highestMasterDate
        ) {
          highestMasterDate = masterList.EffectiveToDate;
        }
      }
    );
    tmpActivePeriod?.masterReferenceDetail?.TableReference.forEach(
      masterList => {
        if (
          (smallestMasterDate &&
            masterList.EffectiveFromDate &&
            moment(smallestMasterDate).isAfter(masterList.EffectiveFromDate)) ||
          !smallestMasterDate
        ) {
          smallestMasterDate = masterList.EffectiveFromDate;
        }
        if (
          (highestMasterDate &&
            masterList.EffectiveToDate &&
            moment(highestMasterDate).isBefore(masterList.EffectiveToDate)) ||
          !highestMasterDate
        ) {
          highestMasterDate = masterList.EffectiveToDate;
        }
      }
    );

    if (
      (tmpActivePeriod?.dateTypeOption === 'MAINTAIN_EFFECTIVE_DATE' ||
        tmpActivePeriod?.dateTypeOption === 'EDIT_EFFECTIVE_DATE') &&
      (!moment(tmpActivePeriod.effectiveEndDate).isSame(
        highestMasterDate,
        'minutes'
      ) ||
        !moment(tmpActivePeriod.effectiveStartDate).isSame(
          smallestMasterDate,
          'minutes'
        ))
    ) {
      if (
        (moment(tmpActivePeriod.effectiveStartDate).isAfter(
          smallestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveStartDate).isSame(
            smallestMasterDate,
            'minutes'
          )) &&
        (moment(tmpActivePeriod.effectiveEndDate).isBefore(
          highestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveEndDate).isSame(
            highestMasterDate,
            'minutes'
          ))
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.engine',
          })
        );
      } else if (
        (moment(tmpActivePeriod.effectiveStartDate).isBefore(
          smallestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveStartDate).isSame(
            smallestMasterDate,
            'minutes'
          )) &&
        (moment(tmpActivePeriod.effectiveEndDate).isAfter(
          highestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveEndDate).isSame(
            highestMasterDate,
            'minutes'
          ))
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.master',
          })
        );
      } else if (
        moment(tmpActivePeriod.effectiveEndDate).isBefore(
          smallestMasterDate,
          'minutes'
        )
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.notmatch',
          })
        );
      } else {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.common',
          })
        );
      }
    }
  }
};
export {
  formatToLongDateTime,
  formatToShortDateTime,
  validateMasterReferenceEffectiveDate,
  customFormatToDateTime,
};
