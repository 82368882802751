import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { TutorialActions, UserActions } from '../actions';
import { useAppDispatch } from './useAppDispatch';
import { useModal } from './useModal';

interface TutorialRouteParams {
  productId: string;
  serviceName: string;
  section?: string;
}

const tutorialParamsSelector = ({
  tutorial: {
    formSpec,
    error,
    serviceName: tutorialServiceName,
    isLoadingFormSpec,
  },
}: STATES.AppState) => ({
  formSpec,
  error,
  tutorialServiceName,
  isLoadingFormSpec,
});

const useTutorialParams = (ignoreError?: boolean) => {
  const dispatch = useAppDispatch();
  const { productId, serviceName, section } = useParams<TutorialRouteParams>();
  const queryStringParams = queryString.parse(window.location.search);
  const token = queryStringParams?.token;
  const {
    formSpec,
    error,
    tutorialServiceName,
    isLoadingFormSpec,
  } = useSelector(tutorialParamsSelector);
  const errorRef = useRef('');
  const { showInfo } = useModal();

  useEffect(() => {
    if (
      !productId ||
      !serviceName ||
      ((isLoadingFormSpec || error) && serviceName === tutorialServiceName)
    ) {
      return;
    }

    if (token) {
      dispatch(UserActions.setAnonymousIdToken(token.toString()));
    }

    if (serviceName !== tutorialServiceName || !formSpec) {
      dispatch(TutorialActions.getFormSpec(productId, serviceName));
    }
  }, [
    dispatch,
    productId,
    serviceName,
    tutorialServiceName,
    isLoadingFormSpec,
    formSpec,
    error,
    token,
  ]);

  const getFormStateByFields = useCallback(
    async (fields: DTO.FormStateInputField[]) => {
      if (!productId || !serviceName || !formSpec || !formSpec.isConstructed) {
        return { fields: {} };
      }

      const formstate = await dispatch(
        TutorialActions.getFormState(productId, serviceName, fields)
      );

      return formstate;
    },
    [dispatch, productId, serviceName, formSpec]
  );

  useEffect(() => {
    if (errorRef.current === error) {
      return;
    }

    if (error && !ignoreError) {
      showInfo({
        content: error,
        okText: 'Global.ok',
      });
    }

    errorRef.current = error;
  }, [error, showInfo, ignoreError]);

  let tutorialUrl = '';
  if (token) {
    tutorialUrl = `/products/${productId}/tutorial/${serviceName}/anonymous/calculator`;
  } else {
    tutorialUrl = `/products/${productId}/tutorial/${serviceName}/calculator`;
  }

  return {
    productId,
    serviceName,
    section,
    formSpec,
    getFormStateByFields,
    tutorialUrl,
    isLoadingFormSpec,
    token,
  };
};

export { useTutorialParams };
