export const GET_MASTER_REFS_REQUEST = 'GET_MASTER_REFS_REQUEST';
export const GET_MASTER_REFS_SUCCESS = 'GET_MASTER_REFS_SUCCESS';
export const GET_MASTER_REFS_FAILURE = 'GET_MASTER_REFS_FAILURE';

export const GET_MASTER_API_REQUEST = 'GET_MASTER_API_REQUEST';
export const GET_MASTER_API_SUCCESS = 'GET_MASTER_API_SUCCESS';
export const GET_MASTER_API_FAILURE = 'GET_MASTER_API_FAILURE';

export const MASTER_REF_UPLOAD_START = 'MASTER_REF_UPLOAD_START';
export const MASTER_REF_UPLOAD_PROGRESS = 'MASTER_REF_UPLOAD_PROGRESS';
export const MASTER_REF_UPLOAD_XHR_REF = 'MASTER_REF_UPLOAD_XHR_REF';
export const MASTER_REF_UPLOAD_SUCCESS = 'MASTER_REF_UPLOAD_SUCCESS';
export const MASTER_REF_UPLOAD_ERROR = 'MASTER_REF_UPLOAD_ERROR';
export const MASTER_REF_UPLOAD_RESET = 'MASTER_REF_UPLOAD_RESET';

export const SELECT_MASTER_REF = 'SELECT_MASTER_REF';

export const DELETE_MASTER_REF_REQUEST = 'DELETE_MASTER_REF_REQUEST';
export const DELETE_MASTER_REF_SUCCESS = 'DELETE_MASTER_REF_SUCCESS';
export const DELETE_MASTER_REF_FAILURE = 'DELETE_MASTER_REF_FAILURE';

export const MASTER_REF_SYNC_REQUEST = 'MASTER_REF_SYNC_REQUEST';
export const MASTER_REF_SYNC_SUCCESS = 'MASTER_REF_SYNC_SUCCESS';
export const MASTER_REF_SYNC_FAILURE = 'MASTER_REF_SYNC_FAILURE';

export const UPDATE_MASTER_REF_REQUEST = 'UPDATE_MASTER_REF_REQUEST';
export const UPDATE_MASTER_REF_SUCCESS = 'UPDATE_MASTER_REF_SUCCESS';
export const UPDATE_MASTER_REF_FAILURE = 'UPDATE_MASTER_REF_FAILURE';

export const GET_MASTER_REF_DETAIL_REQUEST = 'GET_MASTER_REF_DETAIL_REQUEST';
export const GET_MASTER_REF_DETAIL_SUCCESS = 'GET_MASTER_REF_DETAIL_SUCCESS';
export const GET_MASTER_REF_DETAIL_FAILURE = 'GET_MASTER_REF_DETAIL_FAILURE';
