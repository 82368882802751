import { Button, Menu } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { IconSvg } from '../Icons/IconSvg';

const StyledIconButton = styled(Button)<{
  light?: boolean;
  transparent?: boolean;
}>`
  && {
    border: none;
    border-radius: 0 !important;
    padding: 0;
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
    background: ${({ theme, light, transparent }) => {
      if (transparent) {
        return 'transparent';
      }

      if (light) {
        return theme.color.white;
      }

      return theme.color.dark;
    }};
    .anticon {
      color: ${({ theme }) => theme.color.aqua} !important;
      font-size: 50px;
    }
    &:hover,
    &:focus {
      background: ${({ theme, light, transparent }) => {
        if (transparent) {
          return 'transparent';
        }

        if (light) {
          return theme.color.dark;
        }

        return theme.color.aqua;
      }};
      .anticon {
        color: ${({ theme, light }) =>
          light ? theme.color.aqua : theme.color.white} !important;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
  && {
    padding: 0;
    border: none;
    background: transparent;
    margin-right: 10px;
    .ant-menu-submenu-title {
      margin: 0;
      padding: 0;
      height: auto !important;
      width: 50px;
      .ant-menu-submenu-arrow {
        display: none;
      }
    }
  }
`;

interface HoverMenuProps {
  light?: boolean;
  className?: string;
  icon?: string;
  transparent?: boolean;
}

const HoverMenu: FC<HoverMenuProps> = ({
  children,
  light,
  className,
  icon,
  transparent,
}) => {
  return (
    <StyledMenu mode="vertical" className={className}>
      <Menu.SubMenu
        title={
          <StyledIconButton
            data-testid="hover-menu"
            type="link"
            light={light}
            transparent={transparent}
          >
            <IconSvg type={icon || 'plus-square'} />
          </StyledIconButton>
        }
      >
        {children}
      </Menu.SubMenu>
    </StyledMenu>
  );
};

export { HoverMenu };
