import React, { useRef } from 'react';
import { Menu, Layout } from 'antd';
import { useLocation, Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Logo, IconSvg } from '../Common';
import { useActionPermission } from '../../hooks';

const { Item, SubMenu } = Menu;
const { Sider } = Layout;

const SideBarRootItemCss = css`
  height: 60px;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: ${({ theme }) => theme.vars.secondaryColor};
    font-weight: bold;
  }
  span {
    font-size: 16px;
    line-height: 30px;
    height: 30px;
  }
`;

const SideBarRootItemHrefTagCss = css`
  padding: 0 17px;
  color: ${({ theme }) => theme.vars.secondaryColor};
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
  > span {
    display: flex;
    align-items: center;
    .anticon {
      font-size: 40px;
    }
  }
`;

const SideBarMenuWrapper = styled(Sider)`
  && {
    z-index: 6;
    width: 64px !important;
    max-width: 64px !important;
    min-width: 64px !important;
    overflow: auto;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.vars.primaryColor};
    .ant-menu {
      background-color: ${({ theme }) => theme.vars.primaryColor};
      border-top-width: 0;
    }
    .ant-menu-vertical {
      border-right: none !important;
    }

    .ant-menu-vertical > .ant-menu-item > a > span .anticon {
      font-size: 30px !important;
      line-height: 30px;
      height: 30px;
    }

    .ant-menu-inline-collapsed {
      width: 64px !important;
    }
    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item {
      ${SideBarRootItemCss}
      padding: 0 !important;
      transition: none !important;
      ::after {
        border: none;
      }
      > a {
        height: 100%;
        display: flex;
        align-items: center;
        transition: none !important;
        ${SideBarRootItemHrefTagCss}
        > span {
          > span {
            max-width: 0;
            transition: none !important;
          }
        }
      }
    }
    .ant-menu-vertical > .ant-menu-submenu-vertical,
    .ant-menu-inline > .ant-menu-submenu-inline {
      ${SideBarRootItemCss}
      .ant-menu-submenu-title {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 !important;
        margin-top: 0;
        margin-bottom: 0;
        > a {
          ${SideBarRootItemHrefTagCss}
        }
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    .ant-menu-vertical .ant-menu-item:not(:last-child),
    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    .ant-menu-vertical > .ant-menu-item:not(:last-child),
    .ant-menu-inline > .ant-menu-item:not(:last-child) {
      border-bottom: none;
    }

    .ant-menu-vertical > .ant-menu-submenu-vertical.ant-menu-submenu-selected,
    .ant-menu-inline > .ant-menu-submenu-inline.ant-menu-submenu-selected,
    .ant-menu-vertical > .ant-menu-submenu-vertical:hover,
    .ant-menu-vertical > .ant-menu-submenu-active,
    .ant-menu-inline > .ant-menu-item.ant-menu-item-selected,
    .ant-menu-vertical > .ant-menu-item.ant-menu-item-selected {
      background-color: ${({ theme }) => theme.vars.secondaryColor};
      font-weight: bold;
      span {
        color: #ffffff;
      }
      .anticon {
        color: #ffffff;
      }

      &:after {
        border-right: 0;
      }
    }
  }
`;

const SideMenuContainer = styled.div`
  .ant-menu-submenu-popup {
    border-radius: 0 !important;
    .ant-menu-submenu > .ant-menu {
      border-radius: 0 !important;
      border: thin solid ${({ theme }) => theme.color.dark};
      background-color: #f6f6f5 !important;
    }
    .ant-menu-vertical.ant-menu {
      background-color: #ececeb !important;
      border-radius: 0 !important;
    }
    .ant-menu-sub.ant-menu-vertical > .ant-menu-item {
      margin: 0;
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 56px;
      a {
        color: ${props => props.theme.vars.secondaryColor} !important;
        :hover {
          color: ${props => props.theme.color.white} !important;
        }
      }
      &:hover {
        background-color: ${({ theme }) =>
          theme.vars.secondaryColor} !important;
      }
    }
    .ant-menu-sub.ant-menu-vertical > .ant-menu-item:not(:last-child) {
      border-bottom: none;
    }
    .ant-menu-vertical {
      .ant-menu-item:not(:last-child) {
        margin-bottom: 0 !important;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${props => props.theme.vars.secondaryColor} !important;
      a {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

const LogoContainer = styled.div`
  background-color: ${({ theme }) => theme.vars.secondaryColor};
  text-align: center;
  padding: 0;
  height: 86px;
  line-height: 82px;
`;

export interface SideMenuItem {
  key: string;
  icon: string;
  to: string;
  title: string;
  actionPermission?: DTO.ActionPermission;
  subMenuItems?: SideMenuItem[];
}

interface SubLayoutSideMenuProps {
  menuItems?: SideMenuItem[];
}

const SubLayoutSideMenu: React.FC<SubLayoutSideMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const { permissionCheck, showPermissionModal } = useActionPermission();
  const sidebarMenuWrapperRef = useRef<HTMLDivElement>(null);

  const renderItems = (menuList: SideMenuItem[]) => {
    return menuList.map(
      ({ subMenuItems, actionPermission, key, icon, to, title }) => {
        if (subMenuItems) {
          return (
            <SubMenu
              popupClassName="product-sidebar-menu"
              key={key}
              title={
                <Link to={to || '#'} onClick={e => !to && e.preventDefault()}>
                  <span>
                    <IconSvg type={icon} />
                    <span>
                      <FormattedMessage id={title} />
                    </span>
                  </span>
                </Link>
              }
            >
              {renderItems(subMenuItems)}
            </SubMenu>
          );
        }

        return (
          <Item key={key}>
            <Link
              to={to || '#'}
              onClick={e => {
                if (!to) {
                  e.preventDefault();
                  return;
                }

                if (actionPermission && !permissionCheck(actionPermission)) {
                  showPermissionModal(e);
                }
              }}
            >
              <span>
                {icon && <IconSvg type={icon} />}
                <span>
                  <FormattedMessage id={title} />
                </span>
              </span>
            </Link>
          </Item>
        );
      }
    );
  };

  if (!menuItems) return null;

  return (
    <>
      <SideBarMenuWrapper collapsed>
        <LogoContainer>
          <Link data-testid="top-left-logo" to="/">
            <Logo type="OnColouredWithoutText" height="30px" />
          </Link>
        </LogoContainer>
        <Menu
          mode="vertical"
          inlineIndent={15}
          selectedKeys={[location.pathname]}
          getPopupContainer={() =>
            sidebarMenuWrapperRef.current || document.body
          }
        >
          {renderItems(menuItems)}
        </Menu>
      </SideBarMenuWrapper>
      <SideMenuContainer ref={sidebarMenuWrapperRef} />
    </>
  );
};

export default SubLayoutSideMenu;
