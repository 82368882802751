import jwt from 'jsonwebtoken';
import { store } from './store';
import { ApiError } from './errors';
import { UserConstants } from '../constants';
import fetchNetworkErrorWrap from './fetchNetworkErrorWrap';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;

const getXHRResponse = <T = {}>(xhr: XMLHttpRequest) => {
  const { status, responseText, response } = xhr;

  const text = responseText || response;
  let payload: T = text;

  if (text) {
    try {
      payload = JSON.parse(text);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return {
    status,
    payload,
    headers: new Headers(),
  };
};

const uploadRequest = <T = {}>(
  method: string,
  url: string,
  body: FormData,
  onUploadProgress?: (uploadPercent: number) => void,
  xhrRef?: (xhr: XMLHttpRequest) => void
): Promise<DTO.ApiResponse<T>> => {
  const { userAuth } = store.getState().auth;
  const id_token = userAuth && userAuth.id_token;
  const decodedJwt = id_token && jwt.decode(id_token);

  const uploadTask = (userToken: string) =>
    new Promise<DTO.ApiResponse<T>>(resolve => {
      const xhr = new XMLHttpRequest();

      if (onUploadProgress && xhr.upload) {
        xhr.upload.onprogress = e =>
          onUploadProgress(e.total > 0 ? (e.loaded / e.total) * 100 : 0);
      }

      xhr.onerror = () => {
        resolve(getXHRResponse<T>(xhr));
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          resolve(getXHRResponse<T>(xhr));

          return;
        }

        resolve(getXHRResponse<T>(xhr));
      };

      xhr.open(method, url);

      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.send(body);

      xhrRef && xhrRef(xhr);
    });

  if (
    !id_token ||
    (decodedJwt && decodedJwt['exp'] && decodedJwt['exp'] * 1000 < Date.now())
  ) {
    const refresh_token = userAuth && userAuth.refresh_token;
    const refreshRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${id_token}`,
      },
      body: JSON.stringify({ refresh_token }),
    };

    const refreshTokenTask = async (): Promise<string> => {
      const result = await fetchNetworkErrorWrap(
        `${USERSTORE_DOMAIN}/users/refresh/`,
        refreshRequestOptions
      );
      const payload = await result.json();
      const newIdToken = payload.id_token;

      if (!newIdToken) {
        store.dispatch({ type: UserConstants.LOGOUT });

        throw new ApiError({
          error_code:
            'You have reached the maximum section time limit. Please login again.',
        });
      }

      store.dispatch({
        type: UserConstants.SET_ID_TOKEN,
        payload: {
          id_token: newIdToken,
        },
      });

      return newIdToken;
    };

    return refreshTokenTask().then(uploadTask);
  }

  return uploadTask(id_token);
};

export default uploadRequest;
