import { ProductDocsConstant } from '../constants';
import {
  ProductDocsAction,
  ProductDocsThunkAction,
  ProductDocsThunkActionSync,
} from './types';
import { ProductService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: DTO.ProductDocType,
  searchTerm: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_REQUEST,
      payload: {
        productName,
        documentType,
      },
    });

    const { payload, status } = await ProductService.getProductDocs(
      page,
      pageSize,
      productName,
      documentType,
      searchTerm
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_SUCCESS,
      payload: {
        total: payload.count,
        docs: payload.data,
        documentType,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_FAILURE,
      payload: {
        error: msg,
        documentType,
      },
    });
  }
};

const uploadDocument = (
  documentType: DTO.ProductDocType,
  productName: string,
  file: File
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_START });

    const { status, payload } = await ProductService.uploadProductDoc(
      documentType,
      productName,
      file,
      uploadProgress => {
        if (uploadProgress === 100) return;

        // 5% for server processing time
        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_SUCCESS,
      payload: {
        result: payload.data,
        documentType,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_ERROR,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteDoc = (
  productName: string,
  fileName: string,
  documentType: DTO.ProductDocType
): ProductDocsThunkAction => async dispatch => {
  dispatch({
    type: ProductDocsConstant.DELETE_DOC_REQUEST,
    payload: {
      productName,
      fileName,
      documentType,
    },
  });

  try {
    const { status, payload } = await ProductService.deleteDocs(
      productName,
      fileName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_SUCCESS,
      payload: {
        productName,
        fileName,
        documentType,
      },
    });

    dispatch(
      AlertActions.success('ProductDocs.menu.delete.success', { fileName })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_FAILURE,
      payload: {
        productName,
        fileName,
        documentType,
        error: msg,
      },
    });
  }
};

const downloadDocument = (
  request: DTO.DownloadDocumentRequest,
  documentType: DTO.ProductDocType
): ProductDocsThunkActionSync => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) return;

  const { id_token } = userAuth;

  window.location.href = ProductService.getDocumentDownloadUrl(
    request,
    documentType,
    id_token
  );
};

const resetAddDocument = (): ProductDocsAction => ({
  type: ProductDocsConstant.ADD_DOCUMENT_RESET,
});

const resetProductDocList = (type: DTO.ProductDocType): ProductDocsAction => ({
  type: ProductDocsConstant.DOCUMENT_LIST_RESET,
  payload: { type },
});

export const ProductDocsActions = {
  getProductDocs,
  uploadDocument,
  resetAddDocument,
  resetProductDocList,
  downloadDocument,
  deleteDoc,
};
